import { actorQuery, contextQuery, updateContextUrl } from '@/helpers/contextQuery'
import { apiRequest } from '@/services/apiService'

export default {
  namespaced: true,
  state: {
    settings: {
      contextId: '',
      contextSite: '',
      contextEsquery: '',
      contextSearchTerm: '',
      contextStartDate: '',
      contextEndDate: '',
      contextRules: [],
    },
    actorLoading: false,
    contextLoading: false,
    isContextView: false,
    contextResults: [],
    actorResults: [],
    lastContextSearchSettings: {},
    error: null,
  },
  getters: {
    contextId (state) {
      return state.settings.contextId
    },
    contextSite (state) {
      return state.settings.contextSite
    },
    contextEsquery (state) {
      return state.settings.contextEsquery
    },
    contextSearchTerm (state) {
      return state.settings.contextSearchTerm
    },
    contextStartDate (state) {
      return state.settings.contextStartDate
    },
    contextEndDate (state) {
      return state.settings.contextEndDate
    },
    contextRules (state) {
      return state.settings.contextRules
    },
    actorLoading (state) {
      return state.actorLoading
    },
    contextLoading (state, getters) {
      return state.contextLoading
    },
    isContextView (state) {
      return state.isContextView
    },
    contextResults (state) {
      return state.contextResults
    },
    actorResults (state) {
      return state.actorResults
    },
    error (state) {
      return state.error
    },
  },
  mutations: {
    setContextId (state, val) {
      state.settings.contextId = val
    },
    setContextSite (state, val) {
      state.settings.contextSite = val
    },
    setContextEsQuery (state, val) {
      state.settings.contextEsquery = val
    },
    setContextSearchTerm (state, val) {
      state.settings.contextSearchTerm = val
    },
    setContextStartDate (state, val) {
      state.settings.contextStartDate = val
    },
    setContextEndDate (state, val) {
      state.settings.contextEndDate = val
    },
    setContextRules (state, { action, payload }) {
      switch (action) {
        case 'add':
          state.settings.contextRules.push({
            type: payload?.type || 'id',
            operator: payload?.operator || 'equals',
            inputTerm: payload?.inputTerm || '',
            disabled: payload?.disabled || false
          })
          break
        case 'update':
          state.settings.contextRules[payload.index] = {
            type: payload.type,
            operator: payload.operator,
            inputTerm: payload.inputTerm,
            disabled: payload.disabled
          }
          break
        case 'remove':
          state.settings.contextRules.splice(payload, 1)
          break
        case 'clear':
          state.settings.contextRules = [state.settings.contextRules[0]]
          break
        case 'reset':
          state.settings.contextRules = []
          break
      }
    },
    setActorLoading (state, val) {
      state.actorLoading = val
    },
    setContextLoading (state, val) {
      state.contextLoading = val
    },
    setIsContextView (state, val) {
      state.isContextView = val
    },
    setContextResults (state, results) {
      state.contextResults = results
    },
    setActorResults (state, results) {
      state.actorResults = results
    },
    setError (state, val) {
      state.error = val
    },
  },
  actions: {
    async searchContext ({ state, commit, dispatch }) {
      try {
        commit('setContextLoading', true)

        const contentQuery = contextQuery(state.settings)
        const res = await apiRequest(contentQuery)
        if (!res) return
        commit('setContextResults', res.results)
        commit('setError', null)
        const settings = {
          searchTerm: state.settings.contextSearchTerm,
          esquery: state.settings.contextEsquery,
          rules: state.settings.contextRules,
          startDate: state.settings.contextStartDate,
          endDate: state.settings.contextEndDate,
          contextId: state.settings.contextId,
          site: state.settings.contextSite,
        }
        await updateContextUrl(settings)
        const username = res.results[0]?._source.context.username
        await dispatch('searchSettings/addSearchHistory', {
          searchSettings: {
            ...settings,
            searchTerm: state.settings.contextSearchTerm ||
            (username && '@' + username) ||
            'context.id: ' + state.settings.contextId,
          },
          totalResults: res.results.length,
          tool: 'context',
          searchUrl: window.location.href,
          isContext: true
        }, { root: true })
      } catch (err) {
        console.log('err=>', err)
        commit('setError', err)
      } finally {
        commit('setContextLoading', false)
      }
    },
    async initialSearchContext ({ state, commit, dispatch }, { endpoint }) {
      try {
        commit('setActorLoading', true)
        const { settings: { contextId } } = state
        const contentQuery = contextQuery(state.settings)
        let contentRes

        if (endpoint) {
          const query = actorQuery({ id: contextId, endpoint })
          const [content, actorRes] = await Promise.all([
            apiRequest(contentQuery),
            apiRequest(query)
          ])
          contentRes = content
          commit('setContextResults', content.results)
          commit('setActorResults', actorRes)
        } else {
          contentRes = await apiRequest(contentQuery)
          commit('setContextResults', contentRes.results)
          commit('setActorResults', null)
        }
        commit('setError', null)
        const settings = {
          searchTerm: state.settings.contextSearchTerm,
          esquery: state.settings.contextEsquery,
          rules: state.settings.contextRules,
          startDate: state.settings.contextStartDate,
          endDate: state.settings.contextEndDate,
          contextId: state.settings.contextId,
          site: state.settings.contextSite,
        }
        const username = contentRes.results[0]?._source.context.username
        await dispatch('searchSettings/addSearchHistory', {
          searchSettings: {
            ...settings,
            searchTerm: state.settings.contextSearchTerm ||
              (username && '@' + username) ||
              'context.id: ' + state.settings.contextId,
          },
          totalResults: contentRes.results.length,
          tool: 'context',
          searchUrl: window.location.href,
          isContext: true
        }, { root: true })
      } catch (err) {
        commit('setError', err)
      } finally {
        commit('setActorLoading', false)
      }
    }
  }
}
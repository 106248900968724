<template>
  <CustomTable
    :column-config="columnConfig"
    :loading="loading"
    :resultsData="resultsData"
    :header-style="{
      position: 'sticky',
      top: '5rem',
      zIndex: '2',
      paddingTop: 'var(--spacing-xl)',
      marginBottom: 'var(--spacing-xl)',
      backgroundColor: 'var(--color-white)',
    }"
    content-style="padding-bottom: 4rem"
    :pagination-style="{
      width: `calc(100% - ${sidebarMiniState ? '4.714rem' : '366px'})`,
      padding: '0 var(--spacing-xxl)',
      'margin-left': 'calc(-1 * var(--spacing-huge))',
    }"
  >
    <template #cell="{ row, col }">
      <SearchCell
        :row="row"
        :col="col"
        :blurThumbnails="blurThumbnails"
      />
    </template>
  </CustomTable>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { siteWithMediaIsActive } from '@/helpers/searchUtils'
import SearchCell from './SearchCell.vue'
import CustomTable from '../customTable/index.vue'

const { mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    SearchCell,
    CustomTable,
  },
  props: {
    resultsData: Array,
    loading: Boolean,
    showMedia: Boolean,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      columnConfig: [
        { flex: 3, name: 'date', label: 'Date & Time', tooltip: 'Time the content was created', visible: true },
        { flex: 2, name: 'type', label: 'Type', tooltip: 'Type of text content', visible: true },
        { flex: 8, name: 'text', label: 'Text', tooltip: 'Text of the content', visible: true },
        { flex: 3, name: 'sources', label: 'Source', tooltip: 'Source where the content was created', visible: true },
        { flex: 3, name: 'context', label: 'Context', tooltip: 'Name of the channel, group, or page where the content was created', visible: true },
        { flex: 3, name: 'actor', label: 'Actor', tooltip: 'Actor who created the content', visible: true },
        { flex: 2, name: 'engagement', label: 'Engagement', tooltip: 'Engagement data (note: not available for all Sources)', visible: true },
        { flex: 3, name: 'mediatype', label: 'Media type', tooltip: 'Type of media content', visible: false },
        { flex: 3, name: 'thumbnail', label: 'Thumbnail', tooltip: 'Media thumbnail', visible: true },
        { flex: 1, name: 'menu', label: '', visible: true },
      ]
    }
  },
  watch: {
    shouldShowMediaColumns: {
      handler (newVal) {
        this.columnConfig.find(col => col.name === 'mediatype').visible = newVal
        this.columnConfig.find(col => col.name === 'thumbnail').visible = newVal
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['lastSearchSettings', 'sidebarMiniState']),
    shouldShowMediaColumns () {
      return siteWithMediaIsActive(this.lastSearchSettings?.websites ?? []) && this.showMedia
    },
  },
}
</script>
<template>
  <div class="source-list-cell" @click="popup">
    <WebsiteIcon
      v-for="site in cell.slice(0, 3)"
      class="icon"
      :key="site.name"
      :website="site.name"
    />
    <span v-if="cell.length > 3" class="plus-text">
      +{{ cell.length - 3 }}
    </span>
    <q-popup-proxy ref="menuDropdown" @before-show="filter = ''">
      <DropDown class="menu-dropdown">
        <SearchInput
          v-model="filter"
          placeholder="Search"
          class="filter"
        />
        <div
          v-for="source in filteredSources"
          :key="source.name"
          class="dropdown-item"
        >
          <WebsiteIcon :website="source.name"/>
          {{ source.label }}
        </div>
      </DropDown>
    </q-popup-proxy>
  </div>
</template>

<script>
import WebsiteIcon from '../../WebsiteIcon.vue'
import SearchInput from '../SearchInput.vue'
import DropDown from '../DropDown.vue'

export default {
  components: {
    WebsiteIcon,
    SearchInput,
    DropDown,
  },
  props: {
    cell: Object,
  },
  data () {
    return {
      filter: '',
    }
  },
  computed: {
    filteredSources () {
      return this.cell.filter(source => {
        return source.name.includes(this.filter.toLowerCase())
      })
    },
  },
}
</script>

<style scoped>
.source-list-cell {
  display: flex;
  align-items: center;
  padding-left: 4px;
  cursor: pointer;
}

.icon {
  margin-left: -4px;
}

.plus-text {
  margin-left: var(--spacing-xs);
}

.menu-dropdown {
  position: inherit;
  width: 14rem;
  max-height: 20rem;
  padding: var(--spacing-md) 0;
}

.filter {
  margin: var(--spacing-md) var(--spacing-xl);
}

.dropdown-item {
  cursor: default;
  color: var(--color-tan-600);
  font-family: var(--primary-font);
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  margin: var(--spacing-md) var(--spacing-xxl);
  display: flex;
  gap: var(--spacing-md);
}
</style>
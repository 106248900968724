<template>
  <div class="search-term-wrapper">
    <div
      class="search-term-bar"
    >
      <div class="search-term-content">
        <InputSearchTerm />
        <GhostButton
          style="margin-left: calc(-1 * var(--spacing-xxl))"
          mode="dark"
          @click="showRules = !showRules"
        >
          {{ rulesButtonText }}
        </GhostButton>
      </div>
    </div>
    <AnimatedAccordion :isExpanded="showRules">
      <RulesBar
        :rules="isContextView ? contextRules : rules"
        @update-rule="handleRuleUpdate"
      />
    </AnimatedAccordion>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AnimatedAccordion from '@/animations/AnimatedAccordion.vue'
import InputSearchTerm from './input/InputSearchTerm.vue'
import GhostButton from './input/GhostButton.vue'
import RulesBar from './RulesBar.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')
const { mapGetters: mapContextGetters, mapMutations: mapContextMutations } = createNamespacedHelpers('context')

export default {
  components: {
    AnimatedAccordion,
    InputSearchTerm,
    GhostButton,
    RulesBar,
  },
  data () {
    return {
      showRules: false,
    }
  },
  computed: {
    ...mapGetters(['rules']),
    ...mapContextGetters(['isContextView', 'contextRules']),
    rulesButtonText () {
      const currentRules = this.isContextView ? this.contextRules : this.rules
      const count = currentRules.length > 0 ? ` (${currentRules.length})` : ''
      return this.showRules ? 'Hide Rules' : `Add Rule${count}`
    }
  },
  methods: {
    ...mapMutations(['setRules']),
    ...mapContextMutations(['setContextRules']),
    handleScroll () {
      this.showRules = false
    },
    handleRuleUpdate ({ action, payload }) {
      // Choose mutations based on route
      const mutation = this.isContextView ? this.setContextRules : this.setRules
      mutation({ action, payload })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  watch: {
    $route (to, from) {
      // Clear context rules when leaving searchcontext route
      if (from.name === 'searchcontext' && to.name !== 'searchcontext') {
        this.setContextRules({ action: 'clear' })
        this.showRules = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search-term-wrapper {
  position: relative;
  overflow: visible;
}

.search-term-bar {
  background: url(~@/assets/pixely-bg.png) var(--color-primary-900);
  border-bottom: var(--border-width-4) solid var(--color-primary-800);
}

.search-term-content {
  margin: auto;
  max-width: 800px;
  padding: var(--spacing-huge) 0;

}
</style>
<template>
  <DateRange />
  <Sources />
  <Engagement />
  <Types />
  <MediaFormat />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DateRange from '../components/DateRange.vue'
import Types from '../components/Types.vue'
import Sources from '../components/Sources.vue'
import Engagement from '../components/Engagement.vue'
import MediaFormat from '../components/MediaFormat.vue'

const { mapActions } = createNamespacedHelpers('searchSettings')

export default {
  name: 'BaseSettings',
  components: {
    DateRange,
    Types,
    Sources,
    Engagement,
    MediaFormat,
  },
  methods: {
    ...mapActions(['clickSearch'])
  }
}
</script>

<template>
  <div class="active-header">
    {{ col.label }}
    <BaseIcon v-if="type !== KEYWORDS" name="info_outline" size="20px">
      <BaseTooltip>
        {{ $t(`crawl.activeTooltip.${type}`) }}
      </BaseTooltip>
    </BaseIcon>
  </div>
</template>

<script>
import { KEYWORDS } from '../../constants/crawlRequests'
import BaseIcon from '../BaseIcon.vue'
import BaseTooltip from '../BaseTooltip.vue'

export default {
  components: {
    BaseIcon,
    BaseTooltip,
  },
  props: {
    col: Object,
  },
  data () {
    return {
      KEYWORDS,
    }
  },
  computed: {
    type () {
      return this.$store.getters['crawlRequests/type']
    }
  }
}
</script>

<style scoped lang="scss">
.active-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
}
</style>
<template>
  <DateCell v-if="col.name === 'date'" :date="row?.date"/>
  <TypeCell v-else-if="col.name === 'type'" :type="row?.type"/>
  <TextCell v-else-if="col.name === 'text'" :text="row?.text"/>
  <SourceCell
    v-else-if="col.name === 'sources'"
    :value="row.source.name"
    :label="row.source.label"
  />
  <ContextCell v-else-if="col.name === 'context'" :data="row" />
  <ActorCell v-else-if="col.name === 'actor'" :actor="row.actor" />
  <EngagementCell v-else-if="col.name === 'engagement'" :cell="row.engagement"/>
  <div v-else-if="col.name === 'mediatype'">{{ row.mediatype }}</div>
  <MediaCell
    v-else-if="col.name === 'thumbnail'"
    :cell="row.media"
    :row="row"
    :blurThumbnails="blurThumbnails"
  />
  <div v-else-if="col.name === 'menu'" style="overflow: visible">
    <MenuCell :menu-options="menuOptions" @option-click="handleMenuClick" />
    <SeeDetails
      :isOpen="isSeeDetailsOpen"
      @update:model-value="this.isSeeDetailsOpen = $event"
      :detailsData="row"
    />
  </div>
</template>

<script>
import DateCell from '../DateCell.vue'
import TypeCell from './TypeCell.vue'
import TextCell from './TextCell.vue'
import SourceCell from '../SourceCell.vue'
import ContextCell from '../ContextCell.vue'
import ActorCell from '../ActorCell.vue'
import EngagementCell from './EngagementCell.vue'
import MediaCell from './MediaCell.vue'
import MenuCell from './MenuCell.vue'
import SeeDetails from './SeeDetails.vue'

export default {
  components: {
    DateCell,
    TypeCell,
    TextCell,
    SourceCell,
    ContextCell,
    ActorCell,
    EngagementCell,
    MediaCell,
    MenuCell,
    SeeDetails
  },
  props: {
    row: Object,
    col: Object,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      isSeeDetailsOpen: false,
      menuOptions: [
        { label: 'See Details' },
        { label: 'View Media' },
      ]
    }
  },
  methods: {
    handleMenuClick (option) {
      switch (option.label) {
        case 'See Details':
          this.isSeeDetailsOpen = true
          break
        case 'View Media':
          // handle view media
          break
      }
    }
  }
}
</script>
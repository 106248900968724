<template>
  <div
    class="long-text"
    @click.stop="popup = true"
    ref="truncatedRef"
  >
    {{ truncated }}
    <PopupWrapper
      :isOpen="popup || truncatedHovered"
      @update:isOpen="popup = $event"
      :total-items="4"
      :force-direction="popupDirection"
    >
      <DropDown class="menu-dropdown">
        <GhostButton @click="copy" no-padding class="copy-button">
          {{ justCopied ? 'Copied!' : 'Copy' }}
        </GhostButton>
        {{ value }}
      </DropDown>
    </PopupWrapper>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import PopupWrapper from './PopupWrapper.vue'
import DropDown from './input/DropDown.vue'
import GhostButton from './input/GhostButton.vue'

export default {
  components: {
    PopupWrapper,
    DropDown,
    GhostButton,
  },
  props: {
    maxLength: [String, Number],
    value: [String, Number],
    popupDirection: String,
  },
  setup () {
    const truncatedRef = ref()
    const truncatedHovered = useElementHover(truncatedRef)

    return { truncatedRef, truncatedHovered }
  },
  data () {
    return {
      popup: false,
      justCopied: false,
    }
  },
  computed: {
    truncated () {
      if (!this.value) return ''
      const tooLong = this.value.length > Number(this.maxLength)

      if (tooLong) {
        return this.value.slice(0, Number(this.maxLength)) + '…'
      } else {
        return this.value
      }
    },
  },
  methods: {
    copy () {
      window.navigator.clipboard.writeText(this.value)

      this.justCopied = true

      setTimeout(() => {
        this.justCopied = false
      }, 2000)
    },
  },
}
</script>

<style scoped lang="scss">
.long-text {
  cursor: pointer;
  position: relative;
}

.menu-dropdown {
  max-height: 20rem;
  padding: var(--spacing-md);
  cursor: default;
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-950);
}

.copy-button {
  font-size: var(--fs-1);
  padding: var(--spacing-xs) var(--spacing-md);
  margin: auto;
}
</style>
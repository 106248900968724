<template>
  <q-card class="card">
    <div class="inside">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="info">
        <div>
          <slot name="info-left"></slot>
        </div>
        <div class="right">
          <slot name="info-right"></slot>
          <div class="top-right-overlay">
            <slot name="top-right-overlay"></slot>
          </div>
        </div>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.card {
  background: var(--color-white);
  border-radius: var(--border-radius-3);
  overflow: hidden;
}

.inside {
  margin: var(--spacing-4);
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: var(--fs-7);
  font-weight: bold;
  color: var(--color-dark-text);
}

.info {
  display: grid;
  grid-auto-flow: column;
  margin-top: var(--spacing-1);
  font-size: var(--fs-3);
  font-weight: 500;
  color: var(--color-light-text);
}

.info .right {
  justify-self: end;
}

.top-right-overlay {
  position: absolute;
  top: 3rem;
  right: 2.5rem;
}

.content {
  margin-top: var(--spacing-3);
}

.foot-divider {
  width: 100%;
  height: var(--border-width-1);
  background-color: var(--color-dividers);
}

.card-foot {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: var(--spacing-4);
}
</style>

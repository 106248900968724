<template>
  <div class="search-body">
    <div class="search-control-container">
      <SearchControl
        style="padding: 0 var(--spacing-huge)"
        class="search-control"
        :resultsAvailable="resultsAvailable"
        :isContextView="isContextView"
        v-model:displayType="displayType"
        :numResults="numResults"
        v-model:sortBy="sortBy"
        v-model:showMedia="showMedia"
        v-model:blurThumbnails="blurThumbnails"
        @doExport="doExport"
        @summary="showSummary"
      />
    </div>
    <router-view
      v-if="$route.name === SEARCH_CONTEXT"
      :showMedia="showMedia"
      :blurThumbnails="blurThumbnails"
    />
    <div
      v-else
      class="search-results"
    >
      <BodyError v-if="error" :error="error" />
      <template v-else>
        <SearchTable
          v-if="displayType === 'table'"
          :resultsData="resultsData"
          :loading="loading"
          :showMedia="showMedia"
          :blurThumbnails="blurThumbnails"
        />
        <!-- <SearchCards
          v-else-if="displayType === 'cards'"
          :resultsData="resultsData"
          :loading="loading"
        /> -->
        <SearchMedia
          v-else-if="displayType === 'media'"
          :resultsData="resultsData"
          :loading="loading"
          :blurThumbnails="blurThumbnails"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { exportFile } from 'quasar'
import { createNamespacedHelpers } from 'vuex'
import { createArrayCsvStringifier } from '@/helpers/csv-helpers.js'
import { normaliseResults } from '@/helpers/normalise-results'
import SearchControl from '../search/SearchControl.vue'
import BodyError from './BodyError'
import SearchTable from '../search/SearchTable.vue'
// import SearchCards from '../search/SearchCards.vue'
import SearchMedia from '../search/SearchMedia.vue'
const { SEARCH_CONTEXT } = require('@/constants/tools')

const { mapGetters } = createNamespacedHelpers('searchSettings')
const { mapGetters: mapContextGetters } = createNamespacedHelpers('context')

export default {
  components: {
    SearchControl,
    BodyError,
    SearchTable,
    // SearchCards,
    SearchMedia,
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  data () {
    return {
      displayType: 'table',
      sortBy: 'date',
      showMediaData: null,
      blurThumbnailsData: null,
      SEARCH_CONTEXT
    }
  },
  computed: {
    ...mapGetters(['numberOfResults']),
    ...mapContextGetters(['isContextView', 'contextResults']),
    resultsData () {
      return normaliseResults(this.results)?.map(result => ({
        date: result.created_at,
        type: result.type,
        text: result.text,
        source: result.website,
        context: result.context,
        actor: result.author,
        engagement: result.engagement,
        mediatype: result.mimetype,
        media: {
          thumbnail_url: result.thumbnail_url,
          media_hash: result.media_hash,
          mimetype: result.mimetype,
        },
        link: result.link,
      }))
    },
    numResults () {
      return this.isContextView ? this.contextResults.length : this.numberOfResults
    },
    showMedia: {
      get () {
        return this.showMediaData
      },
      set (value) {
        localStorage.setItem('show-media', JSON.stringify(value))
        this.showMediaData = value
      }
    },
    blurThumbnails: {
      get () {
        return this.blurThumbnailsData
      },
      set (value) {
        localStorage.setItem('blur-thumbnails', JSON.stringify(value))
        this.blurThumbnailsData = value
      }
    },
  },
  methods: {
    doExport (type) {
      if (type === 'csv') {
        // flatten to look better as CSV
        const results = this.resultsData
          .map(result => {
            const res = {
              ...result,
              sources: result.source.name,
              likes: result.engagement?.likes ?? '',
              reshares: result.engagement?.reshares ?? '',
              replies: result.engagement?.replies ?? '',
              thumbnail_url: result.media?.thumbnail_url,
              mimetype: result.media?.mimetype,
            }
            delete res.engagement
            delete res.media
            delete res.mediatype
            return res
          })

        const resultHeaders = Object.keys(results[0])
        const resultValues = results
          .map(result => Object.values(result))

        const csvStringifier = createArrayCsvStringifier({
          header: resultHeaders,
          alwaysQuote: true,
        })

        const csvString =
          csvStringifier.getHeaderString() +
          csvStringifier.stringifyRecords(resultValues)

        exportFile('open-measures-data.csv', csvString, 'text/csv')
      } else if (type === 'json') {
        exportFile(
          'open-measures-data.json',
          JSON.stringify(this.resultsData, null, 2),
          'application/json'
        )
      }
    },
    showSummary () {
      console.log('show "Search summary" modal')
    },
  },
  created () {
    this.showMediaData = JSON.parse(localStorage.getItem('show-media') ?? 'false')
    this.blurThumbnailsData = JSON.parse(localStorage.getItem('blur-thumbnails') ?? 'true')
  }
}
</script>

<style scoped lang="scss">
.search-body {
  display: flex;
  flex-direction: column;
}

.search-control-container {
  position: sticky;
  z-index: 3;
  background-color: var(--color-white);
  padding-top: var(--spacing-xxl);
  top: 0;
}

.search-results {
  flex: 1;
  padding: 0 var(--spacing-huge);
}

.search-control {
  margin-bottom: var(--spacing-xl);
}
</style>

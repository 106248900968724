module.exports = {
  TIMELINE: 'timeline',
  SEARCH: 'search',
  LINKS: 'links',
  ACTIVITY: 'activity',
  ACCOUNT: 'account',
  CRAWLREQUESTS: 'crawlrequests',
  NETWORK: 'network',
  HOME: 'home',
  DASHBOARD: 'dashboard',
  SEARCH_CONTEXT: 'searchcontext' // naming in SCREAMING_SNAKE_CASE since it’s not an independent tool, making it easier to recognize.
}

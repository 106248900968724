<template>
  <div class="spinner-container">
    <LoadingAnimation :width="50" :height="50"/>
  </div>
</template>

<script>
import LoadingAnimation from '@/animations/LoadingAnimation.vue'

export default {
  components: {
    LoadingAnimation,
  },
}
</script>

<style scoped lang="scss">
.spinner-container {
  height: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="channel-cell">
    <div>
      <contentView
        v-if="data?.context?.id"
        class="icon"
        @click="handleClickContext"
      />
      <BaseTooltip>
        View in context
      </BaseTooltip>
    </div>
    <div class="table-text channel-text">
      {{ data.context?.name }}
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseTooltip from './BaseTooltip.vue'
import contentView from '@/assets/svg/color/contentView.svg?component'
const { SEARCH_CONTEXT } = require('@/constants/tools')

const { mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  props: {
    data: Object,
  },
  components: {
    contentView,
    BaseTooltip
  },
  computed: {
    ...mapGetters(['startDate', 'endDate']),
  },
  methods: {
    handleClickContext () {
      const endpoint = this.data?.context?.openmeasures_meta?.source?.endpoint
      this.$emit('update:model-value', false)
      this.$router.push({
        name: SEARCH_CONTEXT,
        params: { contextId: this.data?.context?.id },
        query: {
          site: this.data?.source?.name,
          startDate: this.startDate,
          endDate: this.endDate,
          esquery: 'boolean_content',
          ...(endpoint && { endpoint })
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.channel-cell {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
}

.icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: var(--spacing-sm);
}

.channel-text {
  font-weight: 500;
  word-break: break-word;
  min-width: 0;
  flex: 1;
}
</style>
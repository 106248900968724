import { isEmpty } from 'lodash'
import router from '@/router'
import { API_BASE_URL } from '@/api/apiConfig'
import { createActorTerm, createContextTerm } from './searchUtils'

export const actorQuery = (settings) => {
  const url = new URL(API_BASE_URL)
  const term = createActorTerm(settings)
  url.pathname = 'actors'
  url.search = new URLSearchParams({
    site: settings.endpoint,
    term,
    fullsearch: true,
    standard_fields: true,
    limit: 10,
  })

  return url.href
}

export const contextQuery = (settings) => {
  const { contextSite, contextStartDate, contextEndDate } = settings
  const url = new URL(API_BASE_URL)
  const term = createContextTerm(settings)
  const querytype = 'query_string'

  url.pathname = 'content'
  url.search = new URLSearchParams({
    site: contextSite,
    term,
    since: contextStartDate,
    until: contextEndDate,
    querytype,
    returnthumbnails: true,
    standard_fields: true,
    limit: 1000,
  })

  return url.href
}

export async function updateContextUrl (settings) {
  // haven't made a search yet
  if (isEmpty(settings)) return

  const rules = settings.rules
    ?.filter(rule => rule.inputTerm) // Filter out rules with empty input
    .map(rule => ({
      type: rule.type,
      operator: rule.operator,
      input: rule.inputTerm
    }))

  const cleanQuery = { ...settings }

  if (rules?.length > 1) {
    cleanQuery.rules = JSON.stringify(rules.slice(1)) // If more than 1 rule, take all except first one
  } else delete cleanQuery.rules // If 1 or 0 rules, don't include rules in URL


  delete cleanQuery.contextId
  // TODO make query handling more consistent across app
  if (cleanQuery.changepoint !== undefined) {
    cleanQuery.changepoint = Boolean(cleanQuery.changepoint)
  }

  const currentQuery = new URLSearchParams(router.currentRoute.value.query)
  currentQuery.sort()
  const newQuery = new URLSearchParams(cleanQuery)
  newQuery.sort()

  if (currentQuery.toString() === newQuery.toString()) return

  await router.replace({ query: cleanQuery })
}
import general from './modules/general'
import searchSettings from './modules/searchSettings'
import crawlRequests from './modules/crawlRequests'
import context from './modules/context'

export default {
  modules: {
    general,
    searchSettings,
    crawlRequests,
    context,
  },
  strict: process.env.NODE_ENV === 'development'
}

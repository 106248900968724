<template>
  <Pill :clickable="false">
    <template #alt>
      <div class="pill-container">
        <div class="text-body-2 text-bold">
          Pro API Quota
        </div>
        <div v-if="!quota">
          Loading...
        </div>
        <div v-else>
          <div class="limit-row">
            Monthly Organization API Request Limit:
            <ProgressBar
              :value="quota.organization_usage.core_api_monthly_requests_count"
              :max="cap"
              :unlimited="cap === -1"
            />
          </div>
          <div class="limit-row">
            Monthly Organization Media Request Limit:
            <ProgressBar
              :value="quota.organization_usage.core_api_monthly_media_requests_count"
              :max="cap"
              :unlimited="cap === -1"
            />
          </div>
        </div>
      </div>
    </template>
  </Pill>
</template>

<script>
import { API_BASE_URL } from '@/api/apiConfig'
import auth0 from '../plugins/auth0'
import Pill from '@/components/Pill.vue'
import ProgressBar from './ProgressBar.vue'

export default {
  components: {
    Pill,
    ProgressBar,
  },
  data () {
    return {
      quota: null,
    }
  },
  computed: {
    cap () {
      return this.quota.monthly_limits.core_api_monthly_request_limit
    },
  },
  methods: {
    async getQuota () {
      const token = await auth0.getAccessTokenSilently()
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }

      const url = new URL(API_BASE_URL)

      url.pathname = 'quota'

      const response = await fetch(
        url.href, {
          headers,
          method: 'GET',
        })

      return await response.json()
    }
  },
  async created () {
    this.quota = await this.getQuota()
  },
}
</script>

<style scoped>
.pill-container {
  flex: 1;
}

.limit-row {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);
}
</style>
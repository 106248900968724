<template>
  <q-linear-progress
    size="22px"
    :value="percentage"
    color="our-light-text"
    instant-feedback
    :style="{ maxWidth: maxWidth }"
  >
    <BaseTooltip v-if="tooltip">
      {{ tooltip }}
    </BaseTooltip>
    <div class="absolute-full flex flex-center">
      <q-badge
        color="white" text-color="black" :label="badgeLabel"></q-badge>
    </div>
  </q-linear-progress>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import BaseTooltip from './BaseTooltip.vue'

const props = defineProps({
  loading: Boolean,
  value: Number,
  max: Number,
  unlimited: Boolean,
  tooltip: String,
  maxWidth: {
    type: String,
    default: '12rem',
  },
})

const percentage = computed(() => {
  if (props.loading) return 0
  if (props.unlimited) return 0

  return props.value / props.max
})

const badgeLabel = computed(() => {
  if (props.loading) return 'Loading…'
  if (props.unlimited) return 'Unlimited'

  return `${props.value} / ${props.max}`
})
</script>
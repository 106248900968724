<template>
  <BaseSettings />
</template>

<script>
import BaseSettings from './base/BaseSettings.vue'

export default {
  components: {
    BaseSettings
  }
}
</script>
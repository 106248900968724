<template>
  <div>
    <BaseCard v-for="website in tableResults" :key="website.label">
      <template #title>
        {{ $t("table.viewFor") }}{{ website.label }}
        <ButtonDownloadCSV
          :titles="[$t('table.date'), $t('table.usage')]"
          :tableData="website.tableData"
        />
      </template>
      <template #content>
        <BaseTable
          :titles="[$t('table.date'), $t('table.usage')]"
          :tableData="website.tableData"
        />
      </template>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from '@/components/BaseCard'
import BaseTable from './BaseTable'
import ButtonDownloadCSV from '@/components/input/ButtonDownloadCSV'

export default {
  components: {
    BaseCard,
    BaseTable,
    ButtonDownloadCSV
  },
  props: {
    resultsData: Array
  },
  computed: {
    tableResults () {
      return this.resultsData.flatMap((website) => {
        return website.before !== undefined
          ? [
              {
                label: this.$t('data.beforeChangepoint', {
                  site: website.label
                }),
                tableData: website.before.map((d) => [d.date, d.usage])
              },
              {
                label: this.$t('data.afterChangepoint', {
                  site: website.label
                }),
                tableData: website.after.map((d) => [d.date, d.usage])
              }
            ]
          : [
              {
                label: website.label,
                tableData: website.data.map((d) => [d.date, d.usage])
              }
            ]
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>

<template>
  <q-dialog
    square
    :model-value="modelValue"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <q-card class="media-modal-card">
      <div class="top">
        <IconButton
          class="close-button"
          variant="secondary"
          @click="$emit('update:model-value', false)"
        >
          <BaseIcon name="close" size="16px"/>
        </IconButton>
      </div>
      <div>
        <VideoPlayer
          v-if="mimetype?.startsWith('video/')"
          :src="mediaUrl"
          :row="result"
          class="media-item"
        />
        <ImagePdfViewer
          v-else-if="mimetype?.startsWith('image/') || mimetype === 'application/pdf'"
          :mediaUrl="mediaUrl"
          :mimetype="mimetype"
          :row="result"
          class="media-item"
        />
        <div v-else>
          Unsupported media mimetype {{ mimetype }}
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import IconButton from '../input/IconButton.vue'
import BaseIcon from '../BaseIcon.vue'
import VideoPlayer from './VideoPlayer.vue'
import ImagePdfViewer from './ImagePdfViewer.vue'
import { getMediaData } from '../../helpers/searchQuery'

export default {
  components: {
    IconButton,
    BaseIcon,
    VideoPlayer,
    ImagePdfViewer,
  },
  props: {
    modelValue: Boolean,
    cell: Object,
    result: Object,
  },
  data () {
    return {
      mediaUrl: null,
    }
  },
  methods: {
    async getMediaUrl () {
      if (!this.mediaUrl) {
        this.mediaUrl = await getMediaData({
          mediaHash: this.cell.media_hash,
          site: this.result.source.name
        })
      }
    }
  },
  watch: {
    async modelValue (val) {
      if (val) {
        await this.getMediaUrl()
      }
    },
  },
  computed: {
    mimetype () {
      return this.result.media.mimetype
    },
  }
}
</script>

<style>
.q-dialog__backdrop {
  background-color: var(--color-primary-950) !important;
  opacity: 0.8;
}
</style>

<style scoped lang="scss">
.media-modal-card {
  background-color: var(--color-off-white);
  border: var(--border-width-1) solid var(--color-tan-950);
  max-width: unset;
  background-color: var(--color-off-white);
  color: var(--color-black);
  font-family: var(--primary-font);
  overflow: hidden;
}

.top {
  display: flex;
  justify-content: right;
}

.close-button {
  position: fixed;
  margin-top: -19px;
  margin-right: -19px;
}

.media-item {
  max-width: 65vw;
  display: flex;
  flex-direction: column;
}
</style>
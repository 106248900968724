<template>
  <div>
    <component
      v-if="computedIcon"
      :is="computedIcon"
      class="type-icon"
      aria-hidden="true"
    />
    <BaseIcon v-else name="o_question_answer" size="16px"/>
    <BaseTooltip style="text-transform: capitalize">
      {{ type }}
    </BaseTooltip>
  </div>
</template>

<script>
import BaseTooltip from '../BaseTooltip.vue'
import RepostIcon from '../../assets/svg/color/repost.svg?component'
import CommentIcon from '../../assets/svg/color/comment.svg?component'
import ReplyIcon from '../../assets/svg/color/reply.svg?component'
import BaseIcon from '../BaseIcon.vue'

export default {
  components: {
    BaseTooltip,
    BaseIcon,
  },
  props: {
    type: String,
  },
  computed: {
    computedIcon () {
      if (this.type === 'message') {
        return RepostIcon
      } else if (this.type === 'post') {
        return ReplyIcon
      } else if (this.type === 'comment') {
        return CommentIcon
      }
      return null
    },
  }
}
</script>

<style scoped>
.type-icon {
  filter: drop-shadow(0.5px 0.5px 0px var(--color-primary-100));
  color: var(--color-primary-900);
}
</style>
<template>
  <GhostButton
    @click="deleteRow"
    style="padding: var(--element-padding-xxs)"
  >
    <BaseIcon name="delete" size="var(--fs-7)" />
  </GhostButton>
</template>

<script>
import GhostButton from '../input/GhostButton.vue'
import BaseIcon from '../BaseIcon.vue'

export default {
  components: {
    GhostButton,
    BaseIcon,
  },
  props: {
    row: Object,
  },
  methods: {
    async deleteRow () {
      this.$store.dispatch('crawlRequests/deleteRow', this.row)
    },
  }
}
</script>
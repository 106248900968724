<template>
  <div class="input-search-term">
    <template v-if="!small">
      <div class="flex-row-between">
        <div class="flex-row-start">
          <div
            v-for="(filter) in filters"
            :key="filter.value"
            class="flex-row-start"
            style="margin-right: var(--spacing-xxl)"
          >
            <RadioButton
              mode="dark"
              :model-value="filter.value === esqueryValue"
              @update:model-value="handleRadioChange(filter.value)">
              <div class="radio-label">
                {{ filter.label }}
              </div>
              <InfoIcon class="info-icon" />
            </RadioButton>
            <BaseTooltip>
              {{ filter.tooltip }}
            </BaseTooltip>
          </div>
        </div>
        <div class="flex-row-start">
          <SearchHelp />
          <ViewHistory/>
        </div>
      </div>
    </template>
    <div class="search-row">
      <SearchInput
        class="search-bar"
        mode="dark"
        :placeholder="placeholderText"
        :model-value="isContextView ? contextSearchTerm : searchTerm"
        @update:model-value="val => isContextView ? setContextSearchTerm(val) : setSearchTerm(val)"
        @enter="handleSearch"
      >
        <EnterArrowIcon
          class="enter-icon"
          @click="handleSearch"
        />
      </SearchInput>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import RadioButton from './RadioButton.vue'
import ViewHistory from './view-history/index.vue'
import SearchHelp from './search-help/index.vue'
import InfoIcon from '@/assets/svg/color/info.svg?.component'
import EnterArrowIcon from '@/assets/svg/color/enterArrow.svg?component'
import SearchInput from './SearchInput.vue'
import BaseTooltip from '../BaseTooltip.vue'

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('searchSettings')
const {
  mapGetters: mapContextGetters,
  mapMutations: mapContextMutations,
  mapActions: mapContextActions
} = createNamespacedHelpers('context')

export default {
  data () {
    return {
      filters: [
        { label: 'Boolean', value: 'boolean_content', tooltip: 'This query option searches a given boolean string within the content field across each source.' },
        { label: 'Advanced', value: 'query_string', tooltip: 'This query option allows users to search within specific data fields and also allows for boolean operators.' }
      ]
    }
  },
  components: {
    RadioButton,
    SearchInput,
    ViewHistory,
    SearchHelp,
    InfoIcon,
    EnterArrowIcon,
    BaseTooltip
  },
  props: {
    small: Boolean,
  },
  computed: {
    ...mapContextGetters(['isContextView', 'contextEsquery', 'contextSearchTerm']),
    ...mapGetters(['searchTerm', 'esquery']),
    hasError () {
      return !this.searchTerm
    },
    esqueryValue () {
      return this.isContextView ? this.contextEsquery : this.esquery
    },
    placeholderText () {
      switch (this.esqueryValue) {
        case 'boolean_content':
          return 'Example: "qanon" OR "wwg1wga"'
        case 'query_string':
          return 'Example: (author.username: "anon123" AND text: "qanon")'
        default:
          return ''
      }
    },
  },
  methods: {
    ...mapContextMutations(['setContextSearchTerm', 'setContextEsQuery']),
    ...mapContextActions(['searchContext']),
    ...mapMutations(['setSearchTerm', 'setEsQuery']),
    ...mapActions(['clickSearch']),
    handleRadioChange (value) {
      if (this.isContextView) {
        this.setContextEsQuery(value)
      } else this.setEsQuery(value)
    },
    handleSearch () {
      this.isContextView ? this.searchContext() : this.clickSearch(this.$route.name)
    }
  },
}
</script>

<style scoped lang="scss">
.input-search-term {
  color: var(--color-white);
}

.radio-label {
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  color: var(--color-white);
  margin-left: var(--spacing-md);
  margin-right: var(--spacing-xs);
}

.info-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-primary-100)
}

.enter-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-tan-300);
  margin-left: var(--spacing-md);
  cursor: pointer;
}

a {
  color: var(--color-light-grey);
}

p {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-bottom: var(--spacing-2);
  font: var(--primary-font);
}

.search-row {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-1);
  margin-top: var(--spacing-3);
}

.search-bar {
  flex: 1;
}
</style>

<template>
  <div class="top-left">
    <FatButton @click="addModalOpen = true" class="add-button">
      <div class="button-content">
        Add {{ type.toUpperCase() }} <BaseIcon name="add"/>
      </div>
    </FatButton>
    <AddModal
      v-model="addModalOpen"
      :type="type"
      :requests-available="requestsAvailable"
      @adding="$emit('adding')"
      @added="$emit('added')"
    />
    <ProgressBar
      :loading="!requestsAvailable"
      :value="requestsAvailable?.used"
      :max="requestsAvailable?.total"
      tooltip="Number of used requests for this request type. Requests only count toward the maximum if they are active."
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import FatButton from '../input/FatButton.vue'
import BaseIcon from '../BaseIcon.vue'
import AddModal from './AddModal.vue'
import ProgressBar from '../ProgressBar.vue'

const {
  mapGetters,
} = createNamespacedHelpers('crawlRequests')

export default {
  components: {
    FatButton,
    BaseIcon,
    AddModal,
    ProgressBar,
  },
  data () {
    return {
      addModalOpen: false,
    }
  },
  computed: {
    ...mapGetters(['type', 'requestsAvailable']),
  },
}
</script>

<style scoped lang="scss">
.add-button {
  margin-bottom: var(--spacing-1);
}

.button-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-0);
}
</style>
<template>
  <div
    :class="['dropdown-wrapper', {
        'show': isOpen,
        'dropdown-up': openUp,
        'dropdown-down': !openUp,
      }]">
    <slot></slot>
  </div>
</template>

<script>
const popupRegistry = new Set()
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    spacing: {
      type: String,
      default: '0'// default spacing if not provided
    },
    totalItems: Number,
    forceDirection: {
      type: String,
      validator: value => !value || value === 'up'
    }
  },
  data () {
    return {
      shouldOpenUpward: false
    }
  },
  computed: {
    openUp () {
      return this.forceDirection === 'up' || this.shouldOpenUpward
    },
  },
  mounted () {
    popupRegistry.add(this) // Add this instance to registry
    document.addEventListener('click', this.handleClickOutside) // Add click outside listener
    document.addEventListener('keydown', this.handleEscKey) // Add escape key listener

    this.updatePosition() // Initial position check
  },
  beforeUnmount () {
    popupRegistry.delete(this) // Remove this instance from registry
    document.removeEventListener('click', this.handleClickOutside) // Remove click outside listener
    document.removeEventListener('keydown', this.handleEscKey) // Remove escape key listener
  },
  methods: {
    handleClickOutside () {
      this.$emit('update:isOpen', false)
    },
    handleEscKey (event) {
      if (event.key === 'Escape') {
        this.$emit('update:isOpen', false)
      }
    },
    updatePosition () {
      if (!this.$el) return

      // Temporarily reset state to get accurate measurements
      this.shouldOpenUpward = false

      // Force a DOM update and measure
      this.$nextTick(() => {
        const rect = this.$el.getBoundingClientRect()

        const spaceBelow = window.innerHeight - rect.top
        const spaceAbove = rect.top
        const minimumSpace = this.totalItems * 36 + 36
        // Update direction based on available space
        this.shouldOpenUpward = spaceBelow < minimumSpace && spaceAbove > spaceBelow
      })
    }
  },
  watch: {
    isOpen (newValue) {
      if (newValue) {
        // Calculate position first
        this.updatePosition()
        // Then close other popups
        this.$nextTick(() => {
          popupRegistry.forEach(popup => {
            if (popup !== this && popup.isOpen) {
              popup.$emit('update:isOpen', false)
            }
          })
        })
      }
    }
  },
}
</script>

<style scoped>
.dropdown-wrapper {
  position: absolute;
  display: none;
}

.dropdown-wrapper.show {
  display: block;
}

.dropdown-down {
  margin-top: v-bind(spacing);
  top: 100%;
}

.dropdown-up {
  bottom: 100%;
  margin-bottom: v-bind(spacing);
}
</style>
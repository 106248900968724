<template>
  <ContextBody
    :showMedia="showMedia"
    :blurThumbnails="blurThumbnails"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ContextBody from '@/components/bodies/ContextBody'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')
const { mapMutations: mapContextMutations, mapActions: mapContextActions } = createNamespacedHelpers('context')

export default {
  components: {
    ContextBody
  },
  props: {
    showMedia: Boolean,
    blurThumbnails: Boolean
  },
  computed: {
    ...mapGetters(['websites'])
  },
  methods: {
    ...mapContextActions(['initialSearchContext']),
    ...mapMutations(['setWebsites']),
    ...mapContextMutations([
      'setIsContextView',
      'setContextRules',
      'setContextId',
      'setContextSite',
      'setContextStartDate',
      'setContextEndDate',
      'setContextSearchTerm',
      'setContextEsQuery'
    ])
  },
  created () {
    this.setIsContextView(true)
    const contextIdParam = this.$route.params.contextId || ''
    const { site, startDate, endDate, searchTerm, rules, esquery, endpoint } = this.$route.query
    const fixedWebsites = this.websites.map((website) => ({
      ...website,
      active: site === website.name
    }))
    this.setWebsites(fixedWebsites)
    this.setContextRules({
      action: 'add',
      payload: {
        type: 'context.id',
        operator: 'equals',
        inputTerm: contextIdParam,
        disabled: true
      }
    })
    try {
      if (rules) {
        const parsedRules = JSON.parse(rules)
        if (Array.isArray(parsedRules)) {
          parsedRules.forEach((rule) => {
            this.setContextRules({
              action: 'add',
              payload: {
                type: rule.type,
                operator: rule.operator,
                inputTerm: rule.input,
                disabled: false
              }
            })
          })
        }
      }
    } catch (error) {
    }
    this.setContextId(contextIdParam)
    this.setContextSite(site)
    this.setContextStartDate(startDate)
    this.setContextEndDate(endDate)
    this.setContextSearchTerm(searchTerm)
    this.setContextEsQuery(esquery)
    this.initialSearchContext({ endpoint })
  },
  beforeUnmount () {
    this.setContextRules({ action: 'reset' })
    this.setIsContextView(false)
  },
}
</script>

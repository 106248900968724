<template>
  <div class="container flex-row-between">
    <div class="page-number">
      {{ pageRangeText}}
    </div>
    <div v-if="isPaginationNeeded" class="flex-row-start pagination-numbers">
      <RightArrow
        style="transform: rotate(180deg)"
        class="arrow-icon"
        :class="{ disabled: currentPage === 1 }"
        @click="handlePageChange(currentPage - 1)"
      />
      <div
        v-for="num in pages"
        :key="num"
        :class="['page-number clickable', {
          active: currentPage === num,
          'disabled-num': onlyPrevNext && num !== currentPage - 1 && num !== currentPage + 1,
        }]"
        @click="handlePageChange(num)"
      >
        {{ num }}
      </div>
      <RightArrow
        class="arrow-icon"
        :class="{ disabled: currentPage === totalPages }"
        @click="handlePageChange(currentPage + 1)"
      />
    </div>
  </div>
</template>

<script>
import RightArrow from '@/assets/svg/color/arrowRight.svg?component'

export default {
  props: {
    totalResults: Number,
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 50
    },
    onlyPrevNext: Boolean, // crawl requests can only go 1 page at a time
  },
  components: {
    RightArrow
  },
  computed: {
    isPaginationNeeded () {
      return this.totalResults > this.pageSize
    },
    pageRangeText () {
      if (!this.totalResults) return '0 - 0'

      const start = (this.currentPage - 1) * this.pageSize + 1
      const end = Math.min(start + this.pageSize - 1, this.totalResults)
      return `${start} - ${end}`
    },
    pages () {
      const pages = []
      if (this.totalPages <= 7) {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i)
        }
      } else {
        if (this.currentPage <= 4) {
          // Show first 5 pages + ... + last 2
          for (let i = 1; i <= 5; i++) pages.push(i)
          pages.push('...')
          pages.push(this.totalPages - 1, this.totalPages)
        } else if (this.currentPage >= this.totalPages - 3) {
          // Show first 2 + ... + last 5 pages
          pages.push(1, 2)
          pages.push('...')
          for (let i = this.totalPages - 4; i <= this.totalPages; i++) pages.push(i)
        } else {
          // Show first 2 + ... + current-1,current,current+1 + ... + last 2
          pages.push(1, 2)
          pages.push('...')
          pages.push(this.currentPage - 1, this.currentPage, this.currentPage + 1)
          pages.push('...')
          pages.push(this.totalPages - 1, this.totalPages)
        }
      }
      return pages
    },
    totalPages () {
      return Math.ceil(this.totalResults / this.pageSize)
    },
  },
  methods: {
    handlePageChange (page) {
      if (page === '...' || page < 1 || page > this.totalPages) return
      this.$emit('update:currentPage', page)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  bottom: 0;
  height: 2.8571rem;
  position: fixed;

  /* generated here https://cssgradient.io/ */
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5036414223892682) 5%, rgba(255,255,255,0.7473389013808649) 10%, rgba(255,255,255,0.8985994055825455) 15%, rgba(255,255,255,1) 100%);
}

.arrow-icon {
  width: var(--fs-7);
  height: var(--fs-7);
  color: var(--color-tan-950);
  cursor: pointer;
}

.page-number {
  font-size: var(--fs-1);
  font-weight: 500;
  color: var(--color-tan-950);
  padding: 0 var(--spacing-md);
  cursor: default;
}

.clickable {
  cursor: pointer;
}

.active {
  color: var(--color-primary-600);
}

.page-number.disabled-num {
  cursor: not-allowed;
}
</style>
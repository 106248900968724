import auth0 from '../plugins/auth0'
import { SITE_KEYS } from '../constants/sites'
import { KEYWORDS, PROFILES, CHANNELS } from '../constants/crawlRequests'
import { API_BASE_URL } from '@/api/apiConfig'

const { COMMUNITIES, GAB, GETTR, BITCHUTE, MEWE, WIMKIN, RUMBLE, MINDS, LBRY, VK, TIKTOK, RUTUBE, OK, TRUTH_SOCIAL } = SITE_KEYS

export function toCrawlType (type) {
  return {
    [KEYWORDS]: 'keyword',
    [PROFILES]: 'profile',
    [CHANNELS]: 'telegram',
  }[type]
}

export const sites = [
  {
    name: COMMUNITIES,
    label: 'Communities',
  },
  {
    name: GETTR,
    label: 'Gettr',
  },
  {
    name: BITCHUTE,
    label: 'Bitchute',
  },
  {
    name: MEWE,
    label: 'MeWe',
  },
  {
    name: WIMKIN,
    label: 'Wimkin',
  },
  {
    name: RUMBLE,
    label: 'Rumble',
  },
  {
    name: MINDS,
    label: 'Minds',
  },
  {
    name: LBRY,
    label: 'LBRY',
  },
  {
    name: VK,
    label: 'VK',
  },
  {
    name: TRUTH_SOCIAL,
    label: 'Truth Social',
  },
  {
    name: TIKTOK,
    label: 'TikTok',
  },
  {
    name: RUTUBE,
    label: 'RUTUBE',
  },
  {
    name: GAB,
    label: 'Gab',
  },
  {
    name: OK,
    label: 'OK',
  }
]

export function siteLabel (siteName) {
  return sites.find(site => site.name === siteName).label
}

export async function apiFetch ({ params, fetchType }) {
  const token = await auth0.getAccessTokenSilently()
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  const url = new URL(API_BASE_URL)

  if (fetchType === 'get') {
    url.pathname = 'get_crawl_requests'
  } else if (fetchType === 'update') { // update or delete
    url.pathname = 'update_crawl_request'
  } else if (fetchType === 'add') {
    url.pathname = 'crawl_request'
  } else {
    throw Error('specify fetch type')
  }

  url.search = new URLSearchParams(params)

  const response = await fetch(
    url.href, {
      headers,
      method: fetchType === 'get'
        ? 'GET'
        : 'POST'
    })

  try {
    return await response.json()
  } catch (err) {
    // if the fetch doesn't return any data (e.g. on POST) then json() throws
    if (err instanceof SyntaxError) return
    throw err
  }
}
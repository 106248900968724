<template>
  <div class="rules-bar flex-row-center">
    <div style="width: 800px">
      <div style="margin-bottom: var(--spacing-xxl);">
        <div
          v-for="(rule, index) in rules"
          :key="rule.value"
          class="flex-row-center content-container"
        >
          <Select
            width="15.1428rem"
            size="sm"
            mode="dark"
            :disabled="rule.disabled"
            :label="rulesTypesList.find(r => r.value === rule.type).label"
            :options="rulesTypesList"
            @select="handleRulesSelect($event, index)"
          />
          <Select
            width="15.1428rem"
            size="sm"
            mode="dark"
            :disabled="rule.disabled"
            :label="operatorList[rule.operator]"
            :options="operatorOptions"
            @select="handleOperatorSelect($event, index)"
          />
          <TextInputElement
            width="22.2857rem"
            mode="dark"
            placeholder="Enter keyword(s)"
            :disabled="rule.disabled"
            :hasError="hasError(index)"
            :modelValue="rule.inputTerm"
            @update:modelValue="value => handleKeywordUpdate(value, index)"
          />
          <DeleteIcon v-if="!rule.disabled" class="delete-icon" @click="handleDelete(index)"/>
          <div v-else style="width: var(--fs-3)" />
        </div>
      </div>
      <div class="flex-row-between" style="margin-left: calc(-1 * var(--spacing-xxl))">
        <GhostButton class="flex-row-start" mode="dark" size="sm" @click="handleAddRule">
          <AddIcon class="add-icon" />
          <div>
            Add Rule
          </div>
        </GhostButton>
        <div class="flex-row-start">
          <Button
            variant="secondary"
            mode="dark"
            @click="handleClearRules"
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from './input/Select.vue'
import TextInputElement from './input/TextInputElement.vue'
import GhostButton from './input/GhostButton.vue'
import Button from './input/Button.vue'
import AddIcon from '@/assets/svg/color/add.svg?.component'
import DeleteIcon from '@/assets/svg/color/delete.svg?.component'

export default {
  data () {
    return {
      rulesTypesList: [
        { value: 'id', label: 'ID', tooltip: 'ID of the content' },
        { value: 'actor.id', label: 'Actor ID', tooltip: 'ID of the actor' },
        { value: 'actor.name', label: 'Actor Name', tooltip: 'Name of the actor' },
        { value: 'actor.username', label: 'Actor Username', tooltip: 'Username of the actor' },
        { value: 'context.id', label: 'Context ID', tooltip: 'ID of the context' },
        { value: 'context.name', label: 'Context Name', tooltip: 'Name of the context' },
        { value: 'context.username', label: 'Context Username', tooltip: 'Username of the context' },
        { value: 'openmeasures_media.enrichments.value', label: 'Media Enrichments', tooltip: 'Enrichments of the media or thumbnail (e.g. OCR, audio transcription, image captioning, etc.)' }
      ],
      operatorList: {
        equals: 'Equals',
        notEquals: 'Does Not Equal',
        contains: 'Contains',
        notContains: 'Does Not Contain'
      }
    }
  },
  props: {
    rules: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Select,
    TextInputElement,
    GhostButton,
    Button,
    DeleteIcon,
    AddIcon
  },
  computed: {
    operatorOptions () {
      return Object.entries(this.operatorList).map(([value, label]) => ({ value, label }))
    }
  },
  methods: {
    hasError (index) {
      return !this.rules[index]?.inputTerm && index < this.rules.length - 1
    },
    handleRulesSelect (newValue, index) {
      this.$emit('update-rule', {
        action: 'update',
        payload: {
          index,
          operator: this.rules[index].operator,
          inputTerm: this.rules[index].inputTerm,
          type: newValue.value
        }
      })
    },
    handleOperatorSelect (newValue, index) {
      this.$emit('update-rule', {
        action: 'update',
        payload: {
          index,
          operator: newValue.value,
          inputTerm: this.rules[index].inputTerm,
          type: this.rules[index].type
        }
      })
    },
    handleKeywordUpdate (value, index) {
      this.$emit('update-rule', {
        action: 'update',
        payload: {
          index,
          operator: this.rules[index].operator,
          inputTerm: value,
          type: this.rules[index].type
        }
      })
    },
    handleAddRule () {
      this.$emit('update-rule', {
        action: 'add'
      })
    },
    handleDelete (index) {
      this.$emit('update-rule', {
        action: 'remove',
        payload: index
      })
    },
    handleClearRules () {
      this.$emit('update-rule', {
        action: 'clear'
      })
    },
  },
}
</script>

<style scoped>
.rules-bar {
  width: 100%;
  background: var(--color-primary-900);
  padding: var(--spacing-huge);
}

.content-container {
  gap: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
}

.delete-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-tan-400);
  cursor: pointer;
}

.add-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  margin-right: var(--spacing-md);
  cursor: pointer;
}
</style>
<template>
  <div>
    <GhostButton
      style="margin-right: calc(-1 * var(--spacing-xxl))"
      mode="dark"
      @click="modalOpen = true"
    >
      View History
    </GhostButton>
    <BaseModal
      v-model="modalOpen"
      max-width="800px"
      width="800px"
      height="750px"
      title="View Query History"
      no-route-dismiss
    >
      <div class="view-history-modal">
        <div class="description">
          Note: Query history is cached by the browser used. Clearing browser history or switching browsers will change the available logs.
        </div>
        <SearchInput v-model="filterTerm" placeholder="Search" class="history-filter"/>
        <CustomTable
          :column-config="columnConfig"
          :resultsData="historyData"
          :itemsPerPage="20"
          dense
          content-style="height: calc(553px - 2.8571rem) /* 2.8571rem is the height of the pagination bar */"
          pagination-style="width: 800px; position: absolute; margin-left: calc(-1 * var(--spacing-huge)); bottom: unset; padding: 0 var(--spacing-xxl);"
        >
          <template #cell="{ row, col }">
            <HistoryCell
              :row="row"
              :col="col"
            />
          </template>
        </CustomTable>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import GhostButton from '../GhostButton.vue'
import BaseModal from '../../BaseModal.vue'
import SearchInput from '../SearchInput.vue'
import HistoryCell from './HistoryCell.vue'
import CustomTable from '../../customTable/index.vue'

const { mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    GhostButton,
    BaseModal,
    SearchInput,
    HistoryCell,
    CustomTable,
  },
  data () {
    return {
      modalOpen: false,
      filterTerm: '',
    }
  },
  computed: {
    ...mapGetters(['page', 'searchHistory']),
    columnConfig () {
      return [
        { flex: 1, name: 'isContext', label: '' },
        { flex: 12, name: 'searchTerm', label: 'Query' },
        { flex: 3, name: 'copy', label: '' },
        { flex: 6, name: 'totalResults', label: 'Total Results' },
        { flex: 6, name: 'sources', label: 'Datasets' },
        { flex: 6, name: 'startDate', label: 'From' },
        { flex: 6, name: 'endDate', label: 'To' },
        { flex: 3, name: 'menu', label: '' },
      ]
    },
    historyData () {
      return this.searchHistory.map(search => {
        const sources = search.isContext
          ? [{ name: search.searchSettings.site }]
          : search.searchSettings.websites.filter(site => site.active)

        return {
          isContext: search.isContext,
          tool: search.tool,
          searchTerm: search.searchSettings.searchTerm,
          copy: {
            ...search.searchSettings,
            websites: sources,
            tool: search.tool,
          },
          totalResults: search.totalResults,
          sources,
          startDate: search.searchSettings.startDate,
          endDate: search.searchSettings.endDate,
          menu: search.searchUrl,
        }
      })
        // .filter(search => search.tool === this.page)
        .filter(item => {
          if (!this.filterTerm) return true
          const searchTerm = this.filterTerm.toLowerCase()
          return item.searchTerm?.toLowerCase().includes(searchTerm)
        })
    },
  },
}
</script>

<style scoped>
.description {
  color: var(--color-tan-950);
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.history-filter {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
}
</style>
<template>
  <div class="active-cell">
    <template v-if="isAdmin">
      <q-spinner v-if="loading" size="1.5rem">
      </q-spinner>
      <BaseToggle
        v-else
        :model-value="value"
        @update:model-value="updateActive"
      />
    </template>
    <template v-else>
      <BaseIcon v-if="value" name="check" color="our-purple"/>
      <BaseIcon v-else name="close" color="our-secondary"/>
    </template>
  </div>
</template>

<script>
import { apiFetch, toCrawlType } from '../../helpers/crawlRequests'
import BaseIcon from '../BaseIcon.vue'
import BaseToggle from '../input/BaseToggle.vue'

export default {
  components: {
    BaseToggle,
    BaseIcon,
  },
  props: {
    value: Boolean,
    row: Object,
    activeType: String,
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters['crawlRequests/isAdmin']
    }
  },
  methods: {
    async updateActive () {
      this.loading = true

      const type = this.$store.getters['crawlRequests/type']

      const params = {
        crawl_type: toCrawlType(type),
        crawl_id: this.row.primary_id ?? this.row.id,
      }

      if (this.activeType === 'crawl') {
        params.crawl_status = !this.value
      } else {
        params.media_status = !this.value
      }

      await apiFetch({ params, fetchType: 'update' })

      this.$store.commit(
        'crawlRequests/setActive', {
          id: params.crawl_id,
          type,
          activeType: this.activeType,
          active: !this.value
        }
      )

      this.loading = false
    }
  }
}
</script>

<style scoped lang="scss">
.active-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
</style>
<template>
  <BaseSettings />
  <Border />
  <InputUrlHost style="padding: var(--spacing-xxl) 0"/>
  <Border />
  <InputNumberOf style="padding: var(--spacing-xxl) 0" :label="$t('setting.numberUrls')" />
</template>

<script>
import BaseSettings from './base/BaseSettings.vue'
import InputNumberOf from '@/components/input/InputNumberOf'
import InputUrlHost from '@/components/input/InputUrlHost'
import Border from './Border.vue'

export default {
  components: {
    BaseSettings,
    InputNumberOf,
    InputUrlHost,
    Border
  }
}
</script>
<template>
  <div class="icon-wrapper">
    <div
      class='icon-case'
      :class='{transparent: activeColor === "transparent", "purple-background": makePurple }'
      :style='{ height: `${size}px`, width: `${size}px`, borderRadius: `${size}px` }'
    >
      <component
        v-if="icon.svg"
        :is="icon.svg"
        class="icon-tool"
        :style="{
          height: `${size * icon.sizeMultiple}px`,
          width: `${size * icon.sizeMultiple}px`,
          color: iconColor === 'light' ? 'var(--color-white)' : 'var(--color-tan-950)'
        }"
      />
      <img
        v-else-if="icon.img"
        :src='icon.img'
        class="icon-tool"
        :style='{
          height: `${size*icon.sizeMultiple}px`,
          width: `${size*icon.sizeMultiple}px`,
        }'
      />
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/color/search.svg?component'
import AnalysisIcon from '@/assets/svg/color/analysis.svg?component'
import LinksIcon from '@/assets/svg/color/links.svg?component'
import ActivityIcon from '@/assets/svg/color/activity.svg?component'
import MediaIcon from '@/assets/media.svg?component'
import ApiIcon from '@/assets/api.svg?component'
import CloseSidebarSvg from '@/assets/sidebarHideIcon.svg?component'
import OpenSidebarSvg from '@/assets/sidebarOpen.svg?component'
import SeeMoreTools from '@/assets/SeeMoreTools.svg?component'
import CrawlIcon from '@/assets/crawl.svg?component'
import NetworkIcon from '@/assets/network.svg?component'
import AdvancedIcon from '@/assets/Enterprise-Thin.svg'
import { CRAWLREQUESTS } from '../../../constants/tools'

export default {
  props: {
    tool: String,
    makePurple: Boolean,
    size: {
      type: Number,
      default: 40,
    },
    noBackground: Boolean,
    iconColor: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value)
    }
  },
  computed: {
    isCurrent () {
      return this.tool === this.$route.name
    },
    activeColor () {
      if (this.noBackground) return 'transparent'
      return (this.isCurrent | this.makePurple) ? 'our-purple' : 'our-light-text'
    },
    icon () {
      return {
        search: { svg: SearchIcon, sizeMultiple: 2.5 },
        links: { svg: LinksIcon, sizeMultiple: 2.5 },
        activity: { svg: ActivityIcon, sizeMultiple: 2.5 },
        trending: { svg: AnalysisIcon, sizeMultiple: 1 }, // 'trending_up',
        timeline: { svg: AnalysisIcon, sizeMultiple: 2.5 }, // 'trending_up',
        api: { svg: ApiIcon, sizeMultiple: 2.5 },
        closeSidebar: { svg: CloseSidebarSvg, sizeMultiple: 0.5 },
        openSidebar: { svg: OpenSidebarSvg, sizeMultiple: 0.5 },
        seeMoreTools: { svg: SeeMoreTools, sizeMultiple: 0.5 },
        [CRAWLREQUESTS]: { svg: CrawlIcon, sizeMultiple: 2.5 },
        network: { svg: NetworkIcon, sizeMultiple: 2.5 },
        media: { svg: MediaIcon, sizeMultiple: 2.5 },
        dashboard: { img: AdvancedIcon, sizeMultiple: 1.5 },
      }[this.tool]
    }
  },
  components: {
    SearchIcon
  }
}
</script>

<style scoped lang="scss">
.icon-wrapper {
  display: inline-block;
  position: relative;
}

.icon-tool {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.icon-case {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}
</style>

<template>
  <div class="buttons-container">
    <Button
      v-for="range in dateRanges"
      :key="range.value"
      variant="tertiary"
      size="sm"
      :mode="mode"
      style="padding: 4px"
      :isActive="selectedRange === range.value"
      @click="handleDateRangeSelection(range.value)"
    >
      {{ range.label }}
    </Button>
  </div>
</template>

<script>
import moment from 'moment'
import Button from '../../input/Button.vue'

export default {
  components: {
    Button
  },
  props: {
    startDate: String,
    endDate: String,
    mode: String
  },
  data () {
    return {
      dateRanges: [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Past 7 days', value: 'past7days' },
        { label: 'Past 30 days', value: 'past30days' },
        { label: 'Past 6 months', value: 'past6months' },
        { label: 'Past Year', value: 'pastyear' }
      ],
    }
  },
  computed: {
    selectedRange () {
      const today = moment().format('YYYY-MM-DD')
      const predefinedRanges = {
        today: { start: today, end: today },
        yesterday: {
          start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          end: today,
        },
        past7days: {
          start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
          end: today,
        },
        past30days: {
          start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
          end: today,
        },
        past6months: {
          start: moment().subtract(6, 'months').format('YYYY-MM-DD'),
          end: today,
        },
        pastyear: {
          start: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          end: today,
        },
      }

      for (const [key, range] of Object.entries(predefinedRanges)) {
        if (this.startDate === range.start && this.endDate === range.end) {
          console.log('key', key)
          return key
        }
      }
      return 'custom'
    }
  },
  methods: {
    handleDateRangeSelection (value) {
      const today = moment().format('YYYY-MM-DD')
      let rangeStart

      switch (value) {
        case 'today':
          rangeStart = today
          break
        case 'yesterday':
          rangeStart = moment().subtract(1, 'day').format('YYYY-MM-DD')
          break
        case 'past7days':
          rangeStart = moment().subtract(7, 'day').format('YYYY-MM-DD')
          break
        case 'past30days':
          rangeStart = moment().subtract(30, 'day').format('YYYY-MM-DD')
          break
        case 'past6months':
          rangeStart = moment().subtract(6, 'months').format('YYYY-MM-DD')
          break
        case 'pastyear':
          rangeStart = moment().subtract(1, 'year').format('YYYY-MM-DD')
          break
        default:
          rangeStart = null
      }

      if (rangeStart && today) {
        this.$emit('date-range-change', {
          startDate: rangeStart,
          endDate: today
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xxl);
}
</style>
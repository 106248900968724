<template>
  <div class="card flex-row-start">
    <div v-if="showMedia">
      <MediaCell
        v-if="data.mediatype"
        :cell="data.media"
        :row="data"
        :showMedia="showMedia"
        :blurThumbnails="blurThumbnails"
        :thumbnailWidth="150"
        :thumbnailHeight="90"
      />
      <div v-else style="width: 150px"/>
    </div>
    <div class="card-content">
      <div style="gap: var(--spacing-xs)" class="flex-row-start">
        <UserIcon class="user-icon" />
        <span class="username-text" style="font-weight: 500">{{ data.name }}</span>
        <span class="username-text">@{{ data.username }}</span>
      </div>
      <slot>
      </slot>
      <div class="flex-row-start">
        <span class="username-text">{{ moment(data.date).format('MM-DD-YYYY · HH:mm a') }}</span>
        <div class="flex-row-start stats-view">
          <template v-for="stat in types">
            <div
              :key="stat.value"
              v-if="data.engagement && data.engagement[stat.value] !== undefined"
              class="stat-count flex-row-start"
              :title="stat.label"
            >
              <BaseToolTip>
                {{ stat.label }}
              </BaseToolTip>
              <div>{{ data.engagement[stat.value] }}</div>
              <component
                :is="stat.icon"
                style="color: var(--color-primary-600)"
                class="icon"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div>
      <MenuCell @option-click="handleMenuClick" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import UserIcon from '@/assets/svg/color/user.svg?component'
import MenuCell from '../search/MenuCell.vue'
import MediaCell from '../search/MediaCell.vue'
import BaseToolTip from '../BaseTooltip.vue'
import engagementTypes from '../../constants/engagement'

export default {
  components: {
    UserIcon,
    MenuCell,
    MediaCell,
    BaseToolTip
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    showMedia: {
      type: Boolean,
      default: false
    },
    blurThumbnails: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      moment,
      types: engagementTypes,
    }
  },
  methods: {
    handleMenuClick (option) {
      console.log(option)
    }
  },
}
</script>


<style scoped lang="scss">
.card {
  display: flex;
  background: var(--color-white);
  gap: var(--spacing-xxl);
  align-items: stretch;
}

.img {
  width: 150px;
  min-height: 90px;
  background-color: pink;
}

.card-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-md);
}

.user-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-tan-950);
}

.username-text {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-950);
}

.body-text {
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  color: var(--color-tan-950);
}

.stats-view {
  margin-left: var(--spacing-xxl);
  gap: var(--spacing-md);
}

.stat-count {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-950);
  gap: var(--spacing-xs);
}

.stat-icon {
  width: var(--fs-3);
  height: var(--fs-3);
}
</style>
<template>
  <div class="content-container">
    <LoadingAnimation
      v-if="actorLoading"
      color="var(--color-white)"
      style="margin-top: 5rem"
      class="flex-row-center"
    />
    <div v-else>
      <div style="margin-bottom: var(--spacing-huge)" class="flex-row-start">
        <div class="site-icon-view flex-row-center">
          <WebsiteIcon :website="contextSite"/>
        </div>
        <div class="site-text">{{ siteLabel }}</div>
      </div>
      <div style="margin-bottom: var(--spacing-md)" class="flex-row-start">
        <div class="type-text">{{ results?.username }}</div>
      </div>
      <div class="post-text">{{ results?.name }}</div>
      <Border mode="dark" width="var(--border-width-1)" />
        <div v-if="results?.created_at">
          <div class="created-at-container flex-row-start">
            <CalendarIcon class="calendar-icon" />
            <div class="subheading-text">Created</div>
            <div class="date-text">
              {{ moment(results?.created_at).format('MMMM DD, YYYY') }}
            </div>
          </div>
        <Border mode="dark" width="var(--border-width-1)" />
      </div>
      <div v-if="results && results.stats && Object.keys(results.stats).length > 0">
        <div class="stats-view">
          <template v-for="stat in types">
            <div
              :key="stat.value"
              v-if="results.stats[stat.value] !== undefined"
              class="count"
              :title="stat.label"
            >
              <BaseToolTip>
                {{ stat.label }}
              </BaseToolTip>
              <div>{{ results.stats[stat.value] }}</div>
              <component :is="stat.icon" class="icon" />
            </div>
          </template>
        </div>
        <Border mode="dark" width="var(--border-width-1)" />
      </div>
      <DateView />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'
import LoadingAnimation from '@/animations/LoadingAnimation.vue'
import Border from './Border.vue'
import WebsiteIcon from '../WebsiteIcon.vue'
import BaseToolTip from '../BaseTooltip.vue'
import DateView from './components/ContextDateRange.vue'
import CalendarIcon from '@/assets/svg/color/calendar.svg?component'
import { SITE_LABELS } from '@/constants/sites'
import { ContextEngagement } from '../../constants/engagement'

const { mapGetters } = createNamespacedHelpers('context')

export default {
  components: {
    LoadingAnimation,
    Border,
    WebsiteIcon,
    BaseToolTip,
    DateView,
    CalendarIcon
  },
  data () {
    return {
      moment,
      SITE_LABELS,
      engagementStats: 0,
      types: ContextEngagement,
    }
  },
  computed: {
    ...mapGetters(['actorLoading', 'actorResults', 'contextSite']),
    results () {
      if (!this.actorResults?.results?.length) return undefined
      return this.actorResults.results[0]._source
    },
    siteLabel () {
      return this.SITE_LABELS[this.contextSite]
    },

  }
}
</script>

<style scoped lang="scss">
.content-container {
  width: 366px;
  background-color: var(--color-primary-900);
  background-color: var(--color-primary-900);
  padding: var(--spacing-huge);
  overflow-y: auto;
}

.site-icon-view {
  width: var(--fs-7);
  height: var(--fs-7);
  background-color: var(--color-white);
  margin-right: var(--spacing-xl);
  box-shadow: 4px 4px 0px 0px var(--color-primary-700);
}

.site-text {
  font-size: var(--spacing-xxxl);
  font-weight: 700;
  line-height: 28px;
  color: var(--color-white);
}

.count {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-white);
  display: inline-flex;
  align-items: center;
}

.icon {
  font-size: var(--fs-1);
  line-height: var(--fs-4);
  font-weight: 300;
  color: var(--color-white);
  margin-left: var(--spacing-xs);
}


.type-text {
  font-size: var(--fs-3);
  font-weight: 700;
  line-height: var(--fs-6);
  color: var(--color-white);
}

.post-text {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-300);
  margin-bottom: var(--spacing-huge)
}

.created-at-container {
  padding: var(--spacing-xxl) 0;
}

.calendar-icon {
  width: var(--fs-3);
  height: var(--fs-3);
  color: var(--color-white);
  margin-right: var(--spacing-xs);
}

.subheading-text {
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-4);
  color: var(--color-white);
  margin-right: var(--spacing-xs);
}

.date-text {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-300);
}

.stats-view {
  display: flex;
  flex: 1;
  gap: var(--spacing-xxl);
  padding: var(--spacing-huge) 0;
  flex-wrap: wrap;
}
</style>
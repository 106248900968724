<template>
  <div class="table-header" :style="headerStyle">
    <div
      v-for="col in columnConfig"
      :key="col.name"
      class="header-text"
      :style="{
        flex: col.flex,
        display: col.visible === false ? 'none' : 'flex',
      }"
    >
      <slot name="header" :col="col" />
      <BaseTooltip v-if="col.tooltip" anchor="bottom left" self="top left">
        {{ col.tooltip }}
      </BaseTooltip>
      <template v-if="!$slots.header">
        {{ col.label }}
      </template>
    </div>
  </div>
  <BodyLoading v-if="loading" />
  <div v-else>
    <div
      v-if="resultsData.length"
      :style="[contentStyle, fadeStyle]"
      class="scroll"
      ref="fadeEl"
    >
      <div
        v-for="(row, index) in paginatedData"
        :key="index"
        class="table-row"
        :style="{ 'border-bottom': index === paginatedData.length - 1 ? 'none' : '1px solid var(--color-tan-300)' }"
      >
        <div
          v-for="col in columnConfig"
          :key="col.name"
          class="cell-wrapper"
          :style="{
            flex: col.flex,
            display: col.visible === false ? 'none' : 'flex',
            padding: dense ? 'var(--spacing-xs) var(--spacing-md)' : 'var(--spacing-md)',
          }"
        >
          <slot name="cell" :row="row" :col="col" />
        </div>
      </div>
    </div>
  </div>
  <slot name="pagination" />
  <PaginationBar
    v-if="!$slots.pagination"
    v-model:currentPage="currentPage"
    :totalResults="resultsData.length"
    :pageSize="itemsPerPage"
    :style="paginationStyle"
  />
</template>

<script>
import BodyLoading from '../bodies/BodyLoading'
import PaginationBar from '../PaginationBar.vue'
import BaseTooltip from '../BaseTooltip.vue'
import { useFadeScroll } from '@/helpers/effects'

export default {
  components: {
    BodyLoading,
    PaginationBar,
    BaseTooltip
  },
  setup () {
    const { fadeEl, fadeStyle } = useFadeScroll('72px')
    return { fadeEl, fadeStyle }
  },
  props: {
    columnConfig: {
      type: Array,
      required: true
    },
    loading: Boolean,
    resultsData: Array,
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    dense: Boolean,
    headerStyle: [String, Object],
    contentStyle: [String, Object],
    paginationStyle: [String, Object],
  },
  data () {
    return {
      currentPage: 1,
    }
  },
  computed: {
    paginatedData () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.resultsData.slice(start, end)
    },
  },
  watch: {
    resultsData () {
      // Reset to first page when new results come in
      this.currentPage = 1
    },
  },
}
</script>

<style scoped>
.table-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-tan-300);
  flex: 1;
}

.header-text {
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-4);
  color: var(--color-tan-600);
  text-transform: capitalize;
  padding: var(--spacing-md);
  align-items: center;
}

.table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid var(--color-tan-300);
  padding: var(--spacing-md) 0;
}

.cell-wrapper {
  min-width: 0;
}
</style>
export function normaliseResults (website) {
  /**
  * Takes the raw result from the fetch and turns it into a nice format
  * that we can use
  */
  if (website === null) return []

  return website.map((websiteResult) => {
    const results = websiteResult.data.results.map(hit => hit._source)
    return results.map(result => {
      const media = result.openmeasures_media?.[0]

      return {
        created_at: result.created_at,
        type: result.datatype,
        text: result.text,
        author: result.actor?.username,
        context: result.context,
        link: result.url,
        engagement: result.stats,
        mimetype: media?.mimetype,
        thumbnail_url: media?.openmeasures_thumbnail_url,
        media_hash: media?._hash,
        website: {
          name: result.openmeasures_meta.source.site.name,
          label: result.openmeasures_meta.source.site.stylized_name,
        },
      }
    })
  }).flat()
}

export const normaliseContextResults = (data) => {
  const results = data.map(hit => hit._source)

  return results.map(result => {
    const media = result.openmeasures_media?.[0]

    return {
      created_at: result.created_at,
      type: result.datatype,
      text: result.text,
      author: result.actor?.username,
      context: result.context,
      link: result.url,
      engagement: result.stats,
      mimetype: media?.mimetype,
      thumbnail_url: media?.openmeasures_thumbnail_url,
      media_hash: media?._hash,
      site: result.site
    }
  })
}
<template>
  <a
    :href="`https://app.openmeasures.io/timeline?searchTerm=${
      row.keyword
    }&websites=${
      siteName
    }&interval=day&startDate=${
      startDate
    }&endDate=${
      endDate
    }&numberOf=10&changepoint=false&esquery=boolean_content`"
    target="_blank"
  >
    <GhostButton class="button-style">
      View Timeline
    </GhostButton>
  </a>
</template>

<script>
import moment from 'moment'
import GhostButton from '../input/GhostButton.vue'

export default {
  components: {
    GhostButton,
  },
  props: {
    row: Object,
  },
  computed: {
    siteName () {
      switch (this.row.source) {
        case 'tiktok': return 'tiktok_video'
        case 'communities': return 'win'
        case 'bitchute': return 'bitchute_video'
        case 'rutube': return 'rutube_video'
        case 'rumble': return 'rumble_video'
        case 'lbry': return 'lbry_video'
        default : return this.row.source
      }
    },
    startDate () {
      return moment.utc().subtract(1, 'months').format('YYYY-MM-DD')
    },
    endDate () {
      return moment.utc().format('YYYY-MM-DD')
    },
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.button-style {
  padding: var(--element-padding-xxs);
  font-size: unset;
}
</style>
import { SITE_KEYS } from '@/constants/sites'

const { TELEGRAM, GETTR, BITCHUTE_VIDEO, RUMBLE_VIDEO, LBRY_VIDEO, TRUTH_SOCIAL, RUTUBE_VIDEO } = SITE_KEYS
const mediaSites = [TELEGRAM, TRUTH_SOCIAL, GETTR, LBRY_VIDEO, BITCHUTE_VIDEO, RUTUBE_VIDEO, RUMBLE_VIDEO]

export const siteWithMediaIsActive = (sites) => {
  // Check if any allowed sites are still active after this change
  return sites.some(site =>
    mediaSites.includes(site.name) && site.active
  )
}

export const mediaSite = (siteName) => {
  return mediaSites.includes(siteName)
}

export const createRulesQuery = (rules) => {
  return rules
    .filter(rule => rule.inputTerm?.trim()) // Only include rules with non-empty input terms
    .map(rule => {
      const term = rule.inputTerm.trim()
      switch (rule.operator) {
        case 'equals':
          return `${rule.type}: "${term}"`
        case 'notEquals':
          return `NOT ${rule.type}: "${term}"`
        case 'contains':
          return `${rule.type}: *${term}*`
        case 'notContains':
          return `NOT ${rule.type}: *${term}*`
        default:
          return null
      }
    })
    .join(' AND ')
}

export const validateDateFormat = (date) => {
  if (!date) return false // empty date is valid
  return (/^\d{4}-\d{2}-\d{2}$/.test(date)) // returns true if format is valid
}

export function createTerm (settings) {
  const { esquery, searchTerm, types, media, engagements, rules } = settings

  const typeString = types
    .filter(type => type.active)
    .map(type => type.name)
    .map(type => `datatype: ${type}`)
    .join(' OR ')

  const mediaString = media
    .filter(media => media.active)
    .map(media => media.name)
    .map(media => `openmeasures_media.mimetype: ${media}*`)
    .join(' OR ')

  const engagementString = engagements
    .map(engagement => `stats.${engagement.value}: [${engagement.min} TO ${engagement.max}]`)
    .join(' OR ')

  const rulesString = createRulesQuery(rules)

  let contentString
  if (esquery === 'boolean_content') { // Boolean
    contentString = `text: ${searchTerm}`
  } else if (esquery === 'query_string') { // Advanced
    contentString = searchTerm
  } else {
    throw Error('Invalid esquery param')
  }

  const queryString = `(${
    [contentString, typeString, mediaString, engagementString, rulesString]
      .filter(Boolean) // only keep filters we've set
      .join(') AND (')
    })`

  return queryString
}

export function createActorTerm (settings) {
  const { searchTerm, id } = settings

  let contentString
  if (searchTerm) {
    contentString = `text: "${searchTerm}"`
  }

  const filteredTerms = [contentString].filter(Boolean)
  const queryString = filteredTerms.length > 0
    ? `(${filteredTerms.join(') AND (')})`
    : ''

  return `id: ${id}${queryString ? ` AND ${queryString}` : ''}`
}

export const createContextTerm = (settings) => {
  const {
    contextEsquery: esquery,
    contextSearchTerm: searchTerm,
    contextRules: rules
  } = settings

  const rulesString = createRulesQuery(rules)
  let contentString

  if (searchTerm?.trim()) {
    if (esquery === 'boolean_content') { // Boolean
      contentString = `text: ${searchTerm}`
    } else if (esquery === 'query_string') { // Advanced
      contentString = searchTerm
    } else {
      throw Error('Invalid esquery param')
    }
  }

  // If we have both content and rules, join them with AND
  // If we only have one, just return that
  // If we have neither, return empty string
  if (contentString && rulesString) {
    return `(${contentString}) AND (${rulesString})`
  }
  return `(${rulesString})`
}
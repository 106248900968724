<template>
  <Button variant="secondary" @click="download">
    {{ $t("table.downloadCSV") }}
  </Button>
</template>

<script>
import { exportFile } from 'quasar'

import { createArrayCsvStringifier } from '@/helpers/csv-helpers.js'
import Button from './Button'

export default {
  components: {
    Button,
  },
  props: {
    titles: Array,
    tableData: Array
  },
  methods: {
    download () {
      const csvStringifier = createArrayCsvStringifier({
        header: this.titles
      })

      const csvString =
        csvStringifier.getHeaderString() +
        csvStringifier.stringifyRecords(this.tableData)

      exportFile('open-measures-data.csv', csvString, 'text/csv')
    }
  }
}
</script>

<style scoped lang="scss"></style>

<template>
  <DropdownWrapper
    :label="label"
    :width="width"
    v-model:isOpen="isOpen"
    :size="size"
    :mode="mode"
    :disabled="disabled"
  >
    <PopupWrapper
      v-model:isOpen="isOpen"
      :total-items="options.length"
    >
      <DropDown :style="{ width }" :mode="mode">
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="['dropdown-item', `dropdown-item-${mode}-mode`]"
          @click="selectOption(option)"
        >
          {{ option.label }}
          <BaseTooltip v-if="option.tooltip">
            {{ option.tooltip }}
          </BaseTooltip>
        </div>
      </DropDown>
    </PopupWrapper>
  </DropdownWrapper>
</template>

<script>
import PopupWrapper from '../PopupWrapper.vue'
import DropDown from './DropDown.vue'
import DropdownWrapper from './DropdownWrapper.vue'
import BaseTooltip from '../BaseTooltip.vue'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value),
    },
    width: String,
    disabled: Boolean,
  },
  data () {
    return {
      isOpen: false,
    }
  },
  components: {
    PopupWrapper,
    DropdownWrapper,
    DropDown,
    BaseTooltip,
  },
  methods: {
    selectOption (option) {
      this.$emit('select', option)
    },
  },

}
</script>

<style scoped>
.dropdown-item {
  padding: var(--spacing-md) var(--spacing-xxl);
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item-light-mode {
  color: var(--color-tan-600);
}

.dropdown-item-dark-mode {
  color: var(--color-tan-400);
}

.dropdown-item:hover {
  color: var(--color-primary-950);
  background-color: var(--color-primary-100);
}
</style>
import { isEmpty } from 'lodash'
import router from '@/router'
import auth0 from '@/plugins/auth0'
import { createTerm } from './searchUtils'
import { API_BASE_URL } from '@/api/apiConfig'
import { SITE_KEYS } from '@/constants/sites'
const { LINKS, TIMELINE, ACTIVITY, SEARCH, CRAWLREQUESTS, DASHBOARD, NETWORK } = require('@/constants/tools')

const { TWITTER, GAB, FOURCHAN, EIGHTKUN, PARLER, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_VIDEO, RUMBLE_COMMENT, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, FEDIVERSE, BLUESKY } = SITE_KEYS

export function queries (settings, page) {
  if (page === SEARCH) {
    const activeWebsites = settings.websites
      .filter((website) => website.active)
      .map((website) => website.name)

    const {
      startDate: since,
      endDate: until
    } = settings
    const querytype = 'query_string'
    const url = new URL(API_BASE_URL)
    const term = createTerm(settings)

    url.pathname = 'content'

    // Create URLSearchParams without sites first
    const searchParams = new URLSearchParams({
      term,
      since,
      until,
      querytype,
      sortdesc: true,
      returnthumbnails: true,
      standard_fields: true,
      limit: 1000,
    })

    // Add each site separately with its own 'site=' parameter
    activeWebsites.forEach(site => {
      searchParams.append('site', site)
    })

    url.search = searchParams

    return [{
      name: 'combined',
      url: url.href
    }]
  }

  return settings.websites
    .filter((website) => website.active)
    .map((website) => {
      const { name: site } = website
      const {
        startDate: since,
        endDate: until,
        interval
      } = settings
      const querytype = 'query_string'
      const url = new URL(API_BASE_URL)

      const term = createTerm(settings)
      switch (page) {
        case LINKS:
          url.pathname = 'content'
          url.search = new URLSearchParams({
            site,
            term,
            since,
            until,
            limit: 10000,
            querytype,
            standard_fields: true,
          })
          break

        case TIMELINE:
          url.pathname = 'timeseries'
          url.search = new URLSearchParams({
            site, term, since, until, interval, querytype, standard_fields: true,
          })

          if (settings.changepoint) url.searchParams.append('changepoint', 'True')

          break

        case ACTIVITY:
          url.pathname = 'activity'
          url.search = new URLSearchParams({
            site,
            term,
            since,
            until,
            agg_by: chooseAggBy(website, settings),
            querytype,
            standard_fields: true,
          })
          break

        default:
          throw new Error(`Invalid page: ${page}`)
      }

      return {
        ...website,
        url: url.href
      }
    })
}

export async function getMediaData ({ mediaHash, site }) {
  const url = new URL(API_BASE_URL)

  url.pathname = 'media'
  url.search = new URLSearchParams({
    media_hash: mediaHash,
    site,
  })

  const token = await auth0.getAccessTokenSilently()

  const res = await fetch(url.href, {
    headers: {
      Authorization: `Bearer ${token}`
    },
  })

  const json = await res.json()

  return json.openmeasures_media_url
}

function chooseAggBy (website, settings) {
  let aggBy = 'author'
  if (website.name === TWITTER) { aggBy = 'screen_name' }
  if (website.name === GAB) { aggBy = 'account.acct' }
  if (website.name === FOURCHAN) { aggBy = 'name' }
  if (website.name === EIGHTKUN) { aggBy = 'name' }
  if (website.name === PARLER) { aggBy = 'username' }
  if (website.name === WIN) { aggBy = 'author' }
  if (website.name === POAL) { aggBy = 'user' }
  if (website.name === TELEGRAM) { aggBy = 'userinfo.username' }
  if (website.name === GETTR) { aggBy = 'uid' }
  if (website.name === BITCHUTE_VIDEO) { aggBy = 'creator' }
  if (website.name === BITCHUTE_COMMENT) { aggBy = 'fullname' }
  if (website.name === TIKTOK_VIDEO) { aggBy = 'author' }
  if (website.name === TIKTOK_COMMENT) { aggBy = 'author' }
  if (website.name === MEWE) { aggBy = 'userid' }
  if (website.name === WIMKIN) { aggBy = 'author' }
  if (website.name === RUMBLE_VIDEO) { aggBy = 'username' }
  if (website.name === RUMBLE_COMMENT) { aggBy = 'username' }
  if (website.name === MINDS) { aggBy = 'user.username' }
  if (website.name === LBRY_VIDEO) { aggBy = 'signing_channel.name' }
  if (website.name === LBRY_COMMENT) { aggBy = 'channel_name' }
  if (website.name === VK) { aggBy = 'author' }
  if (website.name === TRUTH_SOCIAL) { aggBy = 'account.acct' }
  if (website.name === RUTUBE_VIDEO) { aggBy = 'author.name' }
  if (website.name === RUTUBE_COMMENT) { aggBy = 'user.name' }
  if (website.name === OK) { aggBy = 'author' }
  if (website.name === BLUESKY) { aggBy = 'authorProfile.handle' }
  if (website.name === FEDIVERSE) { aggBy = 'account.acct' }
  return aggBy
}

export async function updateUrl (lastSearchSettings) {
  // haven't made a search yet
  if (isEmpty(lastSearchSettings)) return

  // the default url-ification of the websites object is weird so we'll
  // make it nicer
  const websites = lastSearchSettings.websites
    .filter((website) => website.active)
    .map((website) => website.name)
    .join(',')

  const types = lastSearchSettings.types
    .filter((type) => type.active)
    .map((type) => type.name)
    .join(',')

  const media = lastSearchSettings.media
    .filter((media) => media.active)
    .map((media) => media.name)
    .join(',')

  const engagements = lastSearchSettings.engagements
    .map((engagement) => {
      const min = engagement.min || '0'
      const max = engagement.max
      if (max) {
        return `${engagement.value}:${min}-${max}`
      }
      return `${engagement.value}:${min}`
    })
    .join(',')

  const rules = lastSearchSettings.rules
    ?.filter(rule => rule.inputTerm) // Filter out rules with empty input
    .map(rule => ({
      type: rule.type,
      operator: rule.operator,
      input: rule.inputTerm
    }))

  const cleanQuery = {
    ...lastSearchSettings,
    websites,
    types,
    media,
    rules: JSON.stringify(rules),
    ...(engagements && { engagements })
  }
  if (cleanQuery.changepoint !== undefined) {
    cleanQuery.changepoint = Boolean(cleanQuery.changepoint)
  }

  const currentQuery = new URLSearchParams(router.currentRoute.value.query)
  currentQuery.sort()
  const newQuery = new URLSearchParams(cleanQuery)
  newQuery.sort()

  if (currentQuery.toString() === newQuery.toString()) return

  await router.replace({ query: cleanQuery })
}

export const TOOLS = [TIMELINE, SEARCH, LINKS, ACTIVITY]
export const POPUP_TOOLS = [TIMELINE, SEARCH, LINKS, ACTIVITY, CRAWLREQUESTS, DASHBOARD, NETWORK]

export function isTool (route) {
  if (!route) return false
  // If route is a string (route name)
  if (typeof route === 'string') {
    return TOOLS.includes(route)
  }
  // If route is an object (route object)
  return route.matched?.find(match => TOOLS.includes(match.name))?.name || false
}

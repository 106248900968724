<template>
  <div class="context-body flex-row-start">
    <div style="flex: 1"/>
    <LoadingAnimation
      v-if="actorLoading ||contextLoading"
      style="margin-top: 5rem"
      class="flex-row-center"
    />
    <div v-else class="card-container">
      <div v-for="result in paginatedData" :key="result?.id">
        <Card
          :showMedia="showMedia"
          :blurThumbnails="blurThumbnails"
          :data="result"
          style="flex: 6; gap: var(--spacing-xxl)"
        >
          <div class="body-text">
            {{ clippedText(result.text) }}
          </div>
        </Card>
      </div>
    </div>
    <div style="flex: 1"/>
  </div>
  <PaginationBar
    :totalResults="resultsData.length"
    v-model:currentPage="currentPage"
    class="fade-overlay"
    style="width: calc(100% - 366px)"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import LoadingAnimation from '@/animations/LoadingAnimation.vue'
import Card from '@/components/cards/ContextCard.vue'
import { normaliseContextResults } from '@/helpers/normalise-results'
import PaginationBar from '../PaginationBar.vue'

const { mapGetters: mapContextGetters } = createNamespacedHelpers('context')

export default {
  components: {
    LoadingAnimation,
    Card,
    PaginationBar
  },
  props: {
    showMedia: Boolean,
    blurThumbnails: Boolean
  },
  data () {
    return {
      currentPage: 1,
      itemsPerPage: 50,
    }
  },
  computed: {
    ...mapContextGetters(['actorLoading', 'contextLoading', 'contextResults']),
    resultsData () {
      const res = normaliseContextResults(this.contextResults)?.map(result => ({
        name: result.context?.name,
        username: result.context?.username,
        date: result.created_at,
        type: result.type,
        text: result.text,
        site: { name: result.site },
        author: result.author,
        engagement: result.engagement,
        mediatype: result.mimetype,
        media: {
          thumbnail_url: result.thumbnail_url,
          media_hash: result.media_hash,
          mimetype: result.mimetype,
        },
        link: result.link,
      }))
      return res
    },
    paginatedData () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.resultsData.slice(start, end)
    }
  },
  methods: {
    clippedText (text) {
      if (!text) return ''
      return text.length > 200
        ? text.slice(0, 200) + '...'
        : text
    },
  }
}
</script>


<style scoped lang="scss">
.context-body {
  margin-top: var(--spacing-huge);
  width: calc(100vw - 366px);
}

.card-container {
  display: flex;
  flex-direction: column;
  flex: 6;
  gap: var(--spacing-xxl);
}

.body-text {
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  color: var(--color-tan-950);
}

.fade-overlay {
  /* generated here https://cssgradient.io/ */
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5036414223892682) 5%, rgba(255,255,255,0.7473389013808649) 10%, rgba(255,255,255,0.8985994055825455) 15%, rgba(255,255,255,1) 100%);
}
</style>
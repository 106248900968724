<template>
  <LongText class="table-text" :value="text" max-length="250" />
</template>

<script>
import LongText from '@/components/LongText.vue'

export default {
  components: {
    LongText,
  },
  props: {
    text: {
      type: String,
      default: '', // Default value for the text prop
    },
  },
}
</script>
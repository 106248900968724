<template>
  <div style="padding: var(--spacing-huge) 0;">
    <div class="label">Date Range</div>
    <DateRangeButtons
      mode="dark"
      :startDate="contextStartDate"
      :endDate="contextEndDate"
      @date-range-change="handleDateRangeChange"
    />
    <div style="style" class="same-row">
      <InputStartDate mode="dark" :modelValue="contextStartDate" @update:modelValue="handleStartDateChange" />
      <InputEndDate mode="dark" :modelValue="contextEndDate" @update:modelValue="handleEndDateChange" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DateRangeButtons from './DateRangeButtons.vue'
import InputStartDate from '@/components/input/InputStartDate'
import InputEndDate from '@/components/input/InputEndDate'

const { mapGetters: mapContextGetters, mapMutations: mapContextMutations } = createNamespacedHelpers('context')


export default {
  components: {
    DateRangeButtons,
    InputStartDate,
    InputEndDate
  },
  computed: {
    ...mapContextGetters(['contextStartDate', 'contextEndDate']),
  },
  methods: {
    ...mapContextMutations(['setContextStartDate', 'setContextEndDate']),
    handleStartDateChange (payload) {
      this.setContextStartDate(payload)
    },
    handleEndDateChange (payload) {
      this.setContextEndDate(payload)
    },
    handleDateRangeChange ({ startDate, endDate }) {
      this.setContextStartDate(startDate)
      this.setContextEndDate(endDate)
    }
  }
}
</script>

<style scoped>
.label {
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-4);
  color: var(--color-white);
  margin-bottom: var(--spacing-xxl);
}

.same-row {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-xxl);
  margin-top: var(--spacing-xxl);
  padding-bottom: var(--spacing-md);
}
</style>
<template>
  <q-dialog
    :maximized="!large"
    square
    :model-value="modelValue"
    :persistent="persistent"
    :no-route-dismiss="noRouteDismiss"
    @update:model-value="$emit('input', $event), $emit('update:model-value', $event)"
  >
    <q-card
      class='base-modal-container'
      :style="{ 'max-width': maxWidth, width, height }"
    >
      <q-card-section>
      <div class="inside">
        <div class="flex-row-between">
          <div v-if="title" class="title">
            {{ title }}
          </div>
          <slot v-else name="header"></slot>
          <IconButton
            class="flex-row-center"
            style="padding: var(--spacing-xl)"
            @click="$emit('input', false), $emit('update:model-value', false)"
          >
            <BaseIcon name="close" :size="'var(--fs-3)'"/>
          </IconButton>
        </div>
        <slot></slot>
      </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import IconButton from './input/MiniButtonContainer.vue'
import BaseIcon from './BaseIcon.vue'

export default {
  components: {
    IconButton,
    BaseIcon,
  },
  props: {
    modelValue: Boolean,
    maxWidth: String,
    width: String,
    height: String,
    persistent: { type: Boolean, default: false },
    noRouteDismiss: Boolean,
    title: String,
  },
  data () {
    return {
      large: false,
    }
  },
  methods: {
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    }
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.updateLarge)
  }
}
</script>

<style>
.q-card__section--vert {
  padding: 0 !important;
}

.q-dialog__backdrop {
  background-color: var(--color-primary-950) !important;
  opacity: 0.8;
}
</style>

<style scoped lang="scss">
.base-modal-container {
  background-color: var(--color-off-white);
  border: var(--border-width-1) solid var(--color-tan-950);
  box-shadow: 8px 8px 0px 0px var(--color-primary-100);
}

.inside {
  background-color: var(--color-off-white);
  color: var(--color-black);
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: var(--primary-font);
  padding: 24px;
}

.title {
  color: var(--color-tan-950);
  font-family: Lexend;
  font-size: var(--fs-3);
  font-weight: 700;
  line-height: var(--fs-6);
}

.close {
  width: var(--fs-3);
  height: var(--fs-3);
}

@media (min-width: 599px) {
  .q-dialog__inner--minimized > div {
    max-width: 100%;
  }
}
</style>

import auth0 from '@/plugins/auth0'

export const apiRequest = async (url) => {
  const token = await auth0.getAccessTokenSilently()
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    },
  })

  if (response.status !== 200) {
    const errJson = await response.json()
    throw errJson?.detail ?? errJson?.error ?? new Error('Unknown API error')
  }

  return await response.json()
}
<template>
  <div class="container">
    <template v-if="cell">
      <template v-for="stat in types">
        <div
          :key="stat.value"
          v-if="cell[stat.value] !== undefined"
          class="count flex-row-start"
          :title="stat.label"
        >
          <BaseToolTip>
            {{ stat.label }}
          </BaseToolTip>
          <div>{{ cell[stat.value] }}</div>
          <component :is="stat.icon" class="icon" />
        </div>
      </template>
    </template>
    <div v-else />
  </div>
</template>

<script>
import engagementTypes from '../../constants/engagement'
import BaseToolTip from '../BaseTooltip.vue'

export default {
  props: {
    cell: Object,
  },
  components: {
    BaseToolTip,
  },
  data () {
    return {
      types: engagementTypes,
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.count {
  font-size: var(--fs-1);
  line-height: var(--fs-4);
  font-weight: 300;
  color: var(--color-tan-950);
}

.icon {
  width: var(--fs-3);
  height: var(--fs-3);
  margin-left: var(--spacing-xs);
  color: var(--color-primary-600);
}
</style>
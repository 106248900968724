<template>
  <ActiveHeader v-if="col.name === 'active'" :col="col"/>
  <SourceHeader v-else-if="col.name === 'source'" :col="col"/>
  <template v-else>
    {{ col.label }}
  </template>
</template>

<script>
import ActiveHeader from './ActiveHeader.vue'
import SourceHeader from './SourceHeader.vue'

export default {
  components: {
    ActiveHeader,
    SourceHeader,
  },
  props: {
    col: Object,
  },
}
</script>
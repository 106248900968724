<template>
  <div class="links-body">
    <div v-if="resultsAvailable">
      <div>
        <BaseCard v-for="(website, i) in resultsData" :key="website.label">
          <template #top-right-overlay>
            <Button
              variant="secondary"
              @click="$refs.chart[i].downloadImage()"
            >
              {{ $t("chart.downloadPNG") }}
            </Button>
          </template>
          <template #content>
            <BaseBarChart ref="chart" :resultsData="website.data" :searchSettings="searchSettings" :website="website.label" :barType="$t('chart.linkCountOn')" :subTitle="$t('chart.popularLinksWith')"/>
          </template>
        </BaseCard>
      </div>

      <div>
        <TableCount
          v-for="website in resultsData"
          :key="website.label"
          :card-title="$t('table.viewFor') + website.label"
          :titles="[$t('table.urls'), $t('table.urlCount')]"
          :resultsData="website.data"
        />
      </div>
    </div>
    <BodyLoading v-if="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import { flatten, uniq } from 'lodash'

import BaseCard from '@/components/BaseCard'
import BaseBarChart from '@/components/charts/BaseBarChart'
import Button from '@/components/input/Button'
import TableCount from '@/components/tables/TableCount'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'

export default {
  components: {
    BaseCard,
    BaseBarChart,
    Button,
    TableCount,
    BodyLoading,
    BodyError
  },

  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    searchSettings () {
      return this.lastSearchSettings
    },
    /**
     * Takes the raw result from the fetch and turns it into a nice format
     * that we can use
     */
    resultsData () {
      // results[0].data.hits.hits[0]["_source"]["entities"]["urls"] is array
      if (this.results === null) return []

      return this.results.map((website) => {
        const hits = website.data.results // this be a list
        let allLinks = []


        allLinks = flatten(
          hits
            .map((hit) => hit._source.text)
            .map(this.getAllLinks)
        )
        // TODO move this into vuex actions
        // can write tests over example data, and split out the fetching + processing of different sources, then show page as reuslts arrive!

        const uniqueLinks = uniq(allLinks)
        const links = uniqueLinks.map((link) => {
          const count = allLinks.filter((tag) => tag === link).length
          return {
            key: link,
            count: count
          }
        })

        links.sort((a, b) => b.count - a.count)

        return {
          label: website.label,
          data: links.slice(0, this.lastSearchSettings.numberOf)
        }
      })
    }
  },
  methods: {
    getAllLinks (text) {
      let regex
      if (this.lastSearchSettings.hostRegex) {
        regex = /https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+/g
      } else {
        regex = /https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))+[^ ]*/g
      }
      const match = text.match(regex)
      return match || []
    }
  }
}
</script>

<style scoped lang="scss">
.links-body {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-huge);
}

.card {
  margin-top: var(--spacing-4);
  margin-bottom: 50px;
}
img {
  width: 25%;
}
</style>

<template>
  <BaseSettings />
  <Border />
  <InputInterval style="padding: var(--spacing-xxl) 0" />
  <Border />
  <InputChangepoint style="padding: var(--spacing-xxl) 0" />
</template>

<script>
import BaseSettings from './base/BaseSettings.vue'
import InputInterval from '@/components/input/InputInterval'
import InputChangepoint from '@/components/input/InputChangepoint'
import Border from './Border.vue'

export default {
  components: {
    BaseSettings,
    InputInterval,
    InputChangepoint,
    Border
  }
}
</script>

import { KEYWORDS, PROFILES, CHANNELS } from '../../constants/crawlRequests'
import { toCrawlType, sites, apiFetch } from '../../helpers/crawlRequests'
import router from '../../router'

export default {
  namespaced: true,
  state: {
    loading: true,
    searchTerm: '',
    results: null,
    totalRowCount: 15,
    sites: sites.map(site => ({
      ...site,
      active: true
    })),
  },
  getters: {
    type () {
      return router.currentRoute.value.params.type || KEYWORDS
    },
    activeSites (state) {
      return state.sites.filter(site => site.active)
    },
    isAdmin (state) {
      return state.results?.is_admin
    },
    rowData (state) {
      return state.results?.data
        // because of how pagination works, rows are sometimes reversed
        // telegram has both primary_id and id. we display id but sort by primary_id
        .toSorted((a, b) => a.primary_id ? a.primary_id > b.primary_id : a.id > b.id)
    },
    paginationLastId (state) {
      return state.results?.pagination_last_id
    },
    requestsAvailable (state) {
      if (!state.results) return null

      return {
        total: state.results.subscription.total,
        used: state.results.subscription.used,
      }
    },
    totalRowCount (state) {
      return state.totalRowCount
    }
  },
  mutations: {
    setSearchTerm (state, val) {
      state.searchTerm = val
    },
    setSites (state, val) {
      state.sites = val
    },
    clearResults (state) {
      state.results = null
    },
    setResults (state, val) {
      state.results = val
    },
    setTotalRowCount (state, val) {
      state.totalRowCount = val
    },
    setLoading (state, val) {
      state.loading = val
    },
    setActive (state, { id, type, activeType, active }) {
      let field = null
      if (type === KEYWORDS || type === PROFILES) {
        field = 'active'
      } else if (type === CHANNELS) {
        if (activeType === 'crawl') {
          field = 'crawling_status'
        } else {
          field = 'media_status'
        }
      }
      const rowIndex = state.results.data.findIndex(row => (row.primary_id ?? row.id) === id)
      state.results.data[rowIndex][field] = active
    },
  },
  actions: {
    async query (
      { state, getters, commit, dispatch },
      args,
    ) {
      let { type, prevPage, nextPage, page } = args ?? {}
      type = type ?? getters.type
      page = page ?? 1
      const searchTerm = state.searchTerm

      commit('setLoading', true)

      const params = {
        crawl_type: toCrawlType(type),
        ascending: true,
        limit: 15,
      }

      if (type === KEYWORDS || type === PROFILES) {
        params.sources = getters.activeSites.map(site => site.name).join(',')
      }

      // can't go "back" to page 1, it's supposed to look like when you're just loading the page
      if (page === 1) prevPage = false

      if (prevPage || nextPage) {
        params.pagination_id = getters.paginationLastId
      }
      if (prevPage) {
        // TODO: pagination doesn't seem to work properly, try e.g. going back and forth between page 2 and 3, it just shifts the rows 1 row up and down. BUT it seems to work the same in the old version, so let's keep it like this for now.
        params.ascending = false
      }

      if (searchTerm && searchTerm !== '') {
        params.crawl_filter = searchTerm
      }

      const responseData = await apiFetch({ params, fetchType: 'get' })

      commit('setResults', responseData)

      if (page === 1) {
        // when paginating, the count in the response acts weird, so we only save it when loading page 1
        commit('setTotalRowCount', responseData.count)
      }

      commit('setLoading', false)
    },
    async deleteRow ({ getters, commit, dispatch }, row) {
      commit('setLoading', true)

      const params = {
        crawl_type: toCrawlType(getters.type),
        crawl_id: row.primary_id ?? row.id,
        crawl_status: false
      }

      await apiFetch({
        params,
        fetchType: 'update'
      })

      dispatch('query')
    }
  },
}
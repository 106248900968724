<template>
  <BaseModal
    :model-value="modelValue"
    @update:model-value="$emit('update:model-value', $event)"
    :title="`Add ${type}`"
  >
    <div class="add-modal-content">
      <div>{{ $t(`crawl.add.${type}`) }}</div>
      <TextInputElement
        :mode="'light'"
        v-model="terms"
        :placeholder="type"
      />
      <template v-if="type === PROFILES">
        <InputDropdown
          label="Select profile type"
          :options="[
            {
              value: 'user',
              label: 'User',
            },
            {
              value: 'group',
              label: 'Group',
            },
            {
              value: 'channel',
              label: 'Channel',
            },
          ]"
          v-model:value="profileType"
        />
        <InputDropdown
          label="Select ID type"
          :options="[
            {
              value: 'profile',
              label: 'Username',
            },
            {
              value: 'source_id',
              label: 'ID',
            },
            {
              value: 'source_url',
              label: 'URL',
            },
          ]"
          v-model:value="idType"
        />
      </template>
      <WebsiteSelectors
        v-if="type !== CHANNELS"
        class="websites"
        v-model:sites="sites"
      />
      <FatButton :disabled="numberNewRequests > remainingTerms || numberNewRequests === 0" @click="submit">
        Add
        {{ numberNewRequests }}
        {{ type }}
        ({{ remainingTerms }} remaining)
        <BaseIcon name="add"/>
      </FatButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '../BaseModal.vue'
import TextInputElement from '../input/TextInputElement.vue'
import WebsiteSelectors from './WebsiteSelectors.vue'
import FatButton from '../input/FatButton.vue'
import BaseIcon from '../BaseIcon.vue'
import InputDropdown from '../input/InputDropdown.vue'
import { sites, apiFetch, toCrawlType } from '../../helpers/crawlRequests'
import { CHANNELS, KEYWORDS, PROFILES } from '../../constants/crawlRequests'
import { SITE_KEYS } from '../../constants/sites'

const { TELEGRAM } = SITE_KEYS

export default {
  components: {
    BaseModal,
    TextInputElement,
    WebsiteSelectors,
    FatButton,
    BaseIcon,
    InputDropdown,
  },
  props: {
    modelValue: Boolean,
    type: String,
    requestsAvailable: Object,
  },
  data () {
    return {
      terms: '',
      profileType: 'user',
      idType: 'profile',
      sites: [],
      PROFILES,
      CHANNELS,
    }
  },
  computed: {
    activeSites () {
      return this.sites.filter(site => site.active)
    },
    splitTerms () {
      return this.terms
        .split(',')
        .map(term => term.trim())
        .filter(term => term)
    },
    numberNewRequests () {
      return this.splitTerms.length * this.activeSites.length
    },
    remainingTerms () {
      return this.requestsAvailable?.total - this.requestsAvailable?.used
    },
  },
  methods: {
    submit () {
      this.$emit('adding')
      this.$emit('update:model-value', false)

      this.activeSites
        .map(site => site.name)
        .map(siteName =>
          this.splitTerms.map(term => ({
            term,
            siteName,
          }))
        )
        .flat()
        .map(async request => {
          const params = {
            crawl_type: toCrawlType(this.type),
          }

          if (!this.type !== CHANNELS) {
            params.source = request.siteName
          }

          if (this.type === KEYWORDS) {
            params.keyword = request.term
          } else if (this.type === PROFILES) {
            params.datatype = this.profileType
            params[this.idType] = request.term
          } else if (this.type === CHANNELS) {
            params.channel = request.term
          }

          await apiFetch({ fetchType: 'add', params })

          this.$emit('added')
        })
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler () {
        if (this.modelValue) {
          this.terms = ''

          if (this.type === CHANNELS) {
            this.sites = [{
              name: TELEGRAM,
              active: true,
            }]
          } else {
            this.sites = sites.map(site => ({
              ...site,
              active: true,
            }))
          }
        }
      },
    },
  }
}
</script>

<style scoped lang="scss">
.add-modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: var(--spacing-4);
  max-width: 25rem;
}

.websites {
  max-height: 20rem;
  overflow: auto;
  padding: var(--spacing-3) 0;
  border: var(--border-width-1) solid var(--color-dark-grey);
}
</style>
<template>
  <div class="source-header">
    {{ col.label }}
    <q-btn
      size="10px"
      round
      flat
      icon="settings"
    >
      <q-popup-proxy
        ref="sourceHeaderPopup"
        transition-show="scale"
        transition-hide="scale"
        anchor="center right"
        self="center left"
        @update:model-value="onPopup"
      >
        <div class="source-popup">
          <WebsiteSelectors v-model:sites="localSites"/>
          <div style="display: flex">
            <GhostButton
              @click="$refs.sourceHeaderPopup.hide()"
              style="margin: auto"
            >
              Apply
            </GhostButton>
          </div>
        </div>
      </q-popup-proxy>
    </q-btn>
  </div>
</template>

<script>
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import WebsiteSelectors from './WebsiteSelectors.vue'
import GhostButton from '../input/GhostButton.vue'

const {
  mapState,
  mapMutations,
} = createNamespacedHelpers('crawlRequests')

export default {
  components: {
    WebsiteSelectors,
    GhostButton,
  },
  props: {
    col: Object,
  },
  data () {
    return {
      localSites: [],
    }
  },
  computed: {
    ...mapState(['sites']),
  },
  methods: {
    ...mapMutations(['setSites']),
    onPopup (open) {
      if (open) {
        this.localSites = _.cloneDeep(this.sites)
      } else {
        if (!_.isEqual(this.localSites, this.sites)) {
          this.setSites(this.localSites)
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.source-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-0);
}

.source-popup {
  margin: var(--spacing-3) 0;
}
</style>
<template>
  <div>
    <div
      v-if="cell.thumbnail_url"
      :class="['media-thumbnail', { blur: blurThumbnails }]"
      :style="{
        'background-image': `url(${cell.thumbnail_url})`,
        width: `${thumbnailWidth}px`,
        height: `${thumbnailHeight}px`
      }"
      @click="mediaModal = true"
      ref="thumbnailRef"
    />
    <div
      v-else-if="cell.mimetype"
      :style="{
        backgroundColor: 'var(--color-primary-900)',
        width: `${thumbnailWidth}px`,
        height: `${thumbnailHeight}px`
      }"
      class="flex-row-center"
    >
      <component
        :is="mediaIcon"
        class="media-icon"
        aria-hidden="true"
      />
    </div>
    <img
      v-if="shouldShowOnHover"
      class="media-popup"
      :src="cell.thumbnail_url"
    />
    <MediaModal
      v-model="mediaModal"
      :cell="cell"
      :result="row"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import MediaModal from './MediaModal.vue'
import ImageIcon from '@/assets/svg/color/imageIcon.svg?component'
import VideoIcon from '@/assets/svg/color/play.svg?component'
import AudioIcon from '@/assets/svg/color/audioIcon.svg?component'
import PDFIcon from '@/assets/svg/color/docIcon.svg?component'

export default {
  setup () {
    const thumbnailRef = ref()
    const thumbnailHovered = useElementHover(thumbnailRef)

    return { thumbnailRef, thumbnailHovered }
  },
  components: {
    MediaModal,
    ImageIcon,
    VideoIcon,
    AudioIcon,
    PDFIcon
  },
  props: {
    cell: Object,
    row: Object,
    blurThumbnails: Boolean,
    thumbnailWidth: {
      type: Number,
      default: 80
    },
    thumbnailHeight: {
      type: Number,
      default: 48
    },
  },
  data () {
    return {
      mediaModal: false,
    }
  },
  computed: {
    shouldShowOnHover () {
      return this.cell.thumbnail_url &&
        !this.blurThumbnails &&
        this.thumbnailHovered &&
        (this.cell.mimetype?.startsWith('image/') ||
          this.cell.mimetype?.startsWith('video/'))
    },
    mediaIcon () {
      const mimetype = this.cell?.mimetype || ''

      switch (true) {
        case mimetype.startsWith('image/'):
          return ImageIcon
        case mimetype.startsWith('video/'):
          return VideoIcon
        case mimetype.startsWith('audio/'):
          return AudioIcon
        case mimetype === 'application/pdf':
          return PDFIcon
        default:
          return null
      }
    }
  },
}
</script>

<style scoped>
.media-thumbnail {
  background-size: cover;
  cursor: pointer;
}

.blur {
  filter: blur(5px);
}

.media-popup {
  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  max-width: 100vw;
  border: var(--border-width-1) solid var(--color-tan-950);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
}

.media-icon {
  width: var(--fs-7);
  height: var(--fs-7);
  color: var(--color-white);
}
</style>
<template>
  <div class="content-container">
    <Accordion title="Date Range">
      <div>
        <DateRangeButtons
          :startDate="startDate"
          :endDate="endDate"
          @date-range-change="handleDateRangeChange"
        />
        <div style="style" class="same-row">
          <InputStartDate v-model="computedStartDate" />
          <InputEndDate v-model="computedEndDate" />
        </div>
      </div>
    </Accordion>
  </div>
  <Border />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import Accordion from '../../Accordion.vue'
import DateRangeButtons from './DateRangeButtons.vue'
import InputStartDate from '@/components/input/InputStartDate'
import InputEndDate from '@/components/input/InputEndDate'
import Border from '../Border.vue'
import { validateDateFormat } from '@/helpers/searchUtils'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  data () {
    return {
      dateRanges: [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Past 7 days', value: 'past7days' },
        { label: 'Past 30 days', value: 'past30days' },
        { label: 'Past 6 months', value: 'past6months' },
        { label: 'Past Year', value: 'pastyear' }
      ],
    }
  },
  components: {
    Accordion,
    DateRangeButtons,
    InputStartDate,
    InputEndDate,
    Border
  },
  computed: {
    ...mapGetters(['startDate', 'endDate']),
    isStartDateHasError () {
      return !validateDateFormat(this.startDate)
    },
    isEndDateHasError () {
      return !validateDateFormat(this.endDate)
    },
    computedStartDate: {
      get () {
        return this.startDate
      },
      set (value) {
        this.setStartDate(value) // Commit mutation when startDate changes
      }
    },
    computedEndDate: {
      get () {
        return this.endDate
      },
      set (value) {
        this.setEndDate(value) // Commit mutation when endDate changes
      }
    },
  },
  methods: {
    ...mapMutations(['setStartDate', 'setEndDate']), // Map Vuex mutations
    handleDateRangeChange ({ startDate, endDate }) {
      this.setStartDate(startDate)
      this.setEndDate(endDate)
    }
  }
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xxl);
}

.same-row {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-xxl);
  margin-top: var(--spacing-xl);
  padding-bottom: var(--spacing-md);
}
</style>
<template>
  <div v-if="col.name === 'isContext'">
    <ContentViewIcon
      v-if="row.isContext"
      width="16"
      height="16"
      style="cursor: pointer;"
      @click="handleClickContext"
    />
    <BaseTooltip>
      View in context
    </BaseTooltip>
  </div>
  <div
    v-else-if="col.name === 'searchTerm'"
    class="table-text"
  >
    {{
      row.searchTerm?.slice(0, 40)
    }}{{
      row.searchTerm?.length > 40 ? '…' : ''
    }}
  </div>
  <div v-else-if="col.name === 'copy'">
    <q-btn
      round
      flat
      icon="content_copy"
      size="10px"
      @click="copyJson"
    ></q-btn>
    <BaseTooltip v-if="justCopiedJson" always-show>
      Copied!
    </BaseTooltip>
  </div>
  <div v-else-if="col.name === 'totalResults'" class="table-text">
    {{ row.totalResults}}
  </div>
  <SourceListCell v-else-if="col.name === 'sources'" :cell="row.sources"/>
  <DateCell
    v-else-if="col.name === 'startDate'"
    :showTime="false"
    :date="row.startDate"
  />
  <DateCell
    v-else-if="col.name === 'endDate'"
    :showTime="false"
    :date="row.endDate"
  />
  <MenuCell
    v-else-if="col.name === 'menu'"
    :menu-options="menuOptions"
    @option-click="handleMenuClick"
  />
  <BaseTooltip
    v-if="justCopiedUrl"
    always-show
    anchor="bottom right"
    self="top right"
  >
    Copied!
  </BaseTooltip>
</template>

<script>
import BaseTooltip from '../../BaseTooltip.vue'
import DateCell from '../../DateCell.vue'
import SourceListCell from './SourceListCell.vue'
import MenuCell from '../../search/MenuCell.vue'
import ContentViewIcon from '@/assets/svg/color/contentView.svg?component'

export default {
  components: {
    BaseTooltip,
    DateCell,
    SourceListCell,
    MenuCell,
    ContentViewIcon
  },
  props: {
    row: Object,
    col: Object,
  },
  data () {
    return {
      justCopiedJson: false,
      justCopiedUrl: false,
      menuOptions: [
        { id: 1, label: 'Load Query' },
        { id: 2, label: 'Copy Query URL' },
      ]
    }
  },
  methods: {
    copyJson () {
      navigator.clipboard.writeText(this.row.searchTerm)

      this.justCopiedJson = true
      setTimeout(() => {
        this.justCopiedJson = false
      }, 1000)
    },
    copyUrl () {
      navigator.clipboard.writeText(this.row.menu)

      this.justCopiedUrl = true
      setTimeout(() => {
        this.justCopiedUrl = false
      }, 1000)
    },
    handleMenuClick (option) {
      if (option.id === 1) {
        window.open(this.row.menu, '_blank')
      } else if (option.id === 2) {
        this.copyUrl()
      }
    },
    handleClickContext () {
      window.open(this.row.menu, '_blank')
    }
  }
}
</script>

<template>
  <button
    @click="$emit('click')"
    :disabled="disabled"
    :class="[
      'button',
      `${variant}-${mode}-mode`,
      `${size}-size`,
      {
        [`disabled-${mode}-mode`]: disabled,
        [`${size}-padding`]: !noPadding,
      }
    ]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary'].includes(value),
    },
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value),
    },
    noPadding: Boolean,
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.button {
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.sm-size {
  font-size: var(--fs-2);
  font-weight: 600;
  line-height: var(--fs-5);
}

.md-size{
  font-size: var(--fs-3);
  font-weight: 600;
  line-height: var(--fs-6);
}

.sm-padding {
  padding: var(--element-padding-xxs);
}

.md-padding {
  padding: var(--element-padding-md);
}

.primary-light-mode {
  color: var(--color-primary-600);
}

.primary-dark-mode {
  color: var(--color-primary-300);
}

.primary-light-mode:not(.disabled-button):hover {
  color: var(--color-primary-700);
}

.primary-dark-mode:not(.disabled-button):hover {
  color: var(--color-primary-100);
}

.secondary-light-mode {
  color: var(--color-tan-950);
}

.secondary-dark-mode {
  color: var(--color-tan-300);
}

.secondary-light-mode:not(.disabled-button):hover {
  color: var(--color-primary-700);
}

.secondary-dark-mode:not(.disabled-button):hover {
  color: var(--color-white);
}

.disabled-light-mode {
  cursor: not-allowed;
  color: var(--color-tan-500);
}

.disabled-dark-mode {
  cursor: not-allowed;
  color: var(--color-tan-700);
}
</style>

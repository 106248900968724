<template>
  <div class="search-control">
    <div class="left-part">
      <!--TODO: this toggle can have a shared base component with the one in TypeTabs.vue-->
      <ToggleButton
        v-if="!isContextView"
        :value="displayType"
        :options="displayOptions"
        @click="handleDisplayMode"
      />
      <MiniButtonContainer
        v-else
        class="flex-row-center"
        style="padding: 0.5rem"
        @click="goBack"
      >
        <ArrowLeftIcon class="back-icon" />
      </MiniButtonContainer>
      {{ numResults }} results
    </div>
    <div class="right-part">
      <LabelToggle
        :model-value="shouldEnableMediaToggle"
        @update:model-value="handleShowMedia"
        label="Show Media"
        :disabled="disableShowMedia || onMediaTab"
        :tooltip="noMediaTooltip"
      />
      <LabelToggle
        :model-value="blurThumbnails"
        @update:model-value="handleBlurThumbnails"
        label="Blur thumbnails"
        :disabled="disableShowMedia"
        :tooltip="noMediaTooltip"
      />
      <!--TODO: there's another dropdown like this in LanguagePickerDropdown.vue-->
      <!--<q-select
        label="Sort by: Date"
        :model-value="sortBy"
        @update:model-value="$emit('update:sortBy', $event)"
        :options="['date', 'potatoes', 'apples']"
      />-->
      <Select
        v-if="!isContextView"
        :label="'Export'"
        @select="handleExportSelect"
        :options="exportOptions"
        size="sm"
        width="7.086rem"
        :disabled="!resultsAvailable"
      />
      <div>
        <MiniButtonContainer class="flex-row-center" style="padding: 0.786rem" @click="share">
          <ShareIcon class="share-icon" />
        </MiniButtonContainer>
        <BaseTooltip>
          {{ justShared ? 'Link copied!' : 'Share search results' }}
        </BaseTooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { markRaw } from 'vue'
import router from '@/router'
import { mediaSite, siteWithMediaIsActive } from '@/helpers/searchUtils'
import ToggleButton from '../input/ToggleButton.vue'
import LabelToggle from '../input/LabelToggle.vue'
import Select from '../input/Select.vue'
import MiniButtonContainer from '../input/MiniButtonContainer.vue'
import BaseTooltip from '../BaseTooltip.vue'
import TableIcon from '../../assets/svg/color/table.svg?component'
import MediaIcon from '../../assets/svg/color/media.svg?component'
import ShareIcon from '../../assets/svg/color/share.svg?component'
import ArrowLeftIcon from '../../assets/svg/color/arrowLeft.svg?component'
import { SEARCH } from '../../constants/tools'

const { mapGetters } = createNamespacedHelpers('searchSettings')
const { mapGetters: mapContextGetters } = createNamespacedHelpers('context')

export default {
  data () {
    return {
      exportOptions: [
        { label: 'CSV', value: 'csv' },
        { label: 'JSON', value: 'json' }
      ],
      justShared: false,
    }
  },
  components: {
    ToggleButton,
    LabelToggle,
    Select,
    MiniButtonContainer,
    ShareIcon,
    ArrowLeftIcon,
    BaseTooltip,
  },
  props: {
    displayType: String,
    numResults: Number,
    sortBy: String,
    isContextView: Boolean,
    resultsAvailable: Boolean,
    showMedia: Boolean,
    blurThumbnails: Boolean,
  },
  computed: {
    ...mapContextGetters(['isContextView', 'contextSite']),
    ...mapGetters(['lastSearchSettings', 'websites']),
    disableShowMedia () {
      if (this.isContextView) return !mediaSite(this.contextSite)
      return !siteWithMediaIsActive(this.lastSearchSettings?.websites ?? this.websites)
    },
    shouldEnableMediaToggle () {
      return this.disableShowMedia
        ? false
        : this.onMediaTab ? true : this.showMedia
    },
    onMediaTab () {
      return this.displayType === 'media'
    },
    noMediaTooltip () {
      return this.disableShowMedia ? 'Media is not available for selected Sources' : null
    },
    displayOptions () {
      return [
        {
          value: 'table',
          component: markRaw(TableIcon),
        },
        {
          value: 'media',
          component: markRaw(MediaIcon),
          disabled: this.disableShowMedia,
          tooltip: this.noMediaTooltip,
        }
      ]
    },
  },
  methods: {
    handleExportSelect (option) {
      this.$emit('doExport', option.value)
    },
    handleDisplayMode (mode) {
      this.$emit('update:displayType', mode)
    },
    share () {
      navigator.clipboard.writeText(window.location.href)
        .then(() => {
          this.justShared = true
          setTimeout(() => {
            this.justShared = false
          }, 3000)
        })
    },
    goBack () {
      if (router.options.history.state.back) {
        router.back()
      } else {
        router.push({ name: SEARCH })
      }
    },
    handleShowMedia (value) {
      this.$emit('update:showMedia', value)
    },
    handleBlurThumbnails (value) {
      this.$emit('update:blurThumbnails', value)
    },
  }
}
</script>

<style scoped lang="scss">
.search-control {
  display: flex;
  gap: var(--spacing-1);
}

.left-part {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.right-part {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.back-icon {
  height: 24px;
  width: 24px;
}

.share-icon {
  height: 16px;
  width: 16px
}
</style>
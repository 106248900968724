export const SITE_KEYS = {
  COMMUNITIES: 'communities',
  GAB: 'gab',
  FOURCHAN: '4chan',
  EIGHTKUN: '8kun',
  PARLER: 'parler',
  WIN: 'win',
  POAL: 'poal',
  TELEGRAM: 'telegram',
  GETTR: 'gettr',
  BITCHUTE_VIDEO: 'bitchute_video',
  BITCHUTE_COMMENT: 'bitchute_comment',
  BITCHUTE: 'bitchute',
  TIKTOK_VIDEO: 'tiktok_video',
  TIKTOK_COMMENT: 'tiktok_comment',
  TIKTOK: 'tiktok',
  WIMKIN: 'wimkin',
  MEWE: 'mewe',
  RUMBLE_VIDEO: 'rumble_video',
  RUMBLE_COMMENT: 'rumble_comment',
  RUMBLE: 'rumble',
  LBRY_VIDEO: 'lbry_video',
  LBRY_COMMENT: 'lbry_comment',
  LBRY: 'lbry',
  MINDS: 'minds',
  VK: 'vk',
  TRUTH_SOCIAL: 'truthsocial',
  RUTUBE_VIDEO: 'rutube_video',
  RUTUBE_COMMENT: 'rutube_comment',
  RUTUBE: 'rutube',
  OK: 'ok',
  BLUESKY: 'bluesky',
  FEDIVERSE: 'fediverse'
}

export const SITE_LABELS = {
  gab: 'Gab',
  fourchan: '4chan',
  eightkun: '8kun',
  parler: 'Parler',
  poal: 'Poal',
  win: 'Win Communities',
  telegram: 'Telegram',
  gettr: 'Gettr',
  bitchute_video: 'Bitchute Video',
  bitchute_comment: 'Bitchute Comment',
  mewe: 'MeWe',
  wimkin: 'WiMKiN',
  rumble_video: 'Rumble Video',
  rumble_comment: 'Rumble Comment',
  minds: 'minds',
  lbry_video: 'LBRY Video',
  lbry_comment: 'LBRY Comment',
  vk: 'VK',
  truthsocial: 'Truth Social',
  tiktok_video: 'TikTok Video',
  tiktok_comment: 'TikTok Comment',
  rutube_video: 'RUTUBE Video',
  rutube_comment: 'RUTUBE Comment',
  ok: 'OK',
  bluesky: 'Bluesky',
  fediverse: 'Fediverse'
}

import { computed, ref, toRefs } from 'vue'
import { useScroll } from '@vueuse/core'

export function useFadeScroll (fadeHeight = '125px') {
  const fadeEl = ref(null)

  const {
    arrivedState
  } = useScroll(fadeEl)

  const { top: topArrived, bottom: bottomArrived } = toRefs(arrivedState)

  const fadeStyle = computed(() => {
    const fadeTop = !topArrived.value
    const fadeBottom = !bottomArrived.value

    return `mask-image: linear-gradient(to bottom, ${
      fadeTop ? `transparent 0%, black ${fadeHeight}` : ''
    }${
      fadeTop && fadeBottom ? ', ' : ''
    }${
      fadeBottom ? `black calc(100% - ${fadeHeight}), transparent 100%` : ''
    });`
  })

  return { fadeEl, fadeStyle }
}
<template>
  <div>
    <BaseModal
      :model-value="isOpen"
      @update:model-value="$emit('update:model-value', $event)"
      max-width="800px"
      width="800px"
      height="750px"
      no-route-dismiss
    >
      <template #header>
        <Button
          :disabled="!detailsData?.context?.id"
          style="flex-shrink: 0;"
          @click="handleClickContext"
        >
          View Post in Context
        </Button>
      </template>
      <div class="content-wrapper">
      <div class="content">
        <ContextCard
          :data="contextData"
          :isSeeDetailsOpen="true"
          class="flex-row-start context-card"
        >
          <LongText class="context-text" :value="contextData.text" max-length="300" />
        </ContextCard>
      </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseModal from '../BaseModal.vue'
import Button from '../input/Button.vue'
import ContextCard from '../cards/ContextCard.vue'
import LongText from '@/components/LongText.vue'
const { SEARCH_CONTEXT } = require('@/constants/tools')

const { mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    BaseModal,
    Button,
    ContextCard,
    LongText
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    site: String,
    detailsData: Object
  },
  computed: {
    ...mapGetters(['startDate', 'endDate']),
    contextData () {
      return {
        name: this.detailsData?.context?.name,
        username: this.detailsData?.context?.username,
        date: this.detailsData?.date,
        type: this.type,
        text: this.detailsData?.text,
        site: { name: this.detailsData?.site?.name },
        author: this.detailsData?.author,
        engagement: this.detailsData?.engagement,
        link: this.detailsData?.link,
      }
    }
  },
  methods: {
    handleClickContext () {
      const endpoint = this.detailsData?.context?.openmeasures_meta?.source?.endpoint
      this.$emit('update:model-value', false)
      this.$router.push({
        name: SEARCH_CONTEXT,
        params: { contextId: this.detailsData?.context?.id },
        query: {
          site: this.detailsData?.site?.name,
          startDate: this.startDate,
          endDate: this.endDate,
          esquery: 'boolean_content',
          ...(endpoint && { endpoint })
        }
      })
    }
  },
  mounted () {
    this.$emit('update:model-value', false)
  }
}
</script>

<style scoped>
.header-info-text {
  font-size: var(--fs-1);
  font-weight: 300;
  line-height: var(--fs-4);
  color: var(--color-tan-600);
  margin: 0 var(--spacing-xxl);
}

.content-wrapper {
  position: relative;
  height: calc(750px - 100px); /* Subtract header height */
  overflow: hidden;
  margin-top: var(--spacing-md);
}

.content {
  flex: 1;
  margin: var(--spacing-massive) 0;
}

.context-card {
  background-color: var(--color-primary-100);
  border-left: var(--border-width-2) solid var(--color-primary-600);
  padding: var(--spacing-xxl);
}

.context-text {
  font-size: var(--fs-1);
  line-height: var(--fs-4);
  font-weight: 300;
  color: var(--color-tan-950);
}
</style>
<template>
  <div class="media-card" @click="mediaModal = true">
    <div class="thumbnail-container">
      <div
        :class="['thumbnail', { blur: blurThumbnails }]"
        :style="{ 'background-image': `url(${result.media.thumbnail_url})` }"
      />
    </div>
    <div class="details">
      <div class="card-text table-text">
        {{result.text}}
      </div>
      <div class="data">
        <EngagementRow :engagement="result.engagement" />
        <WebsiteIcon :website="result.source.name" />
      </div>
    </div>
    <MediaModal
      v-model="mediaModal"
      :cell="result.media"
      :result="result"
    />
  </div>
</template>

<script>
import EngagementRow from './EngagementRow.vue'
import WebsiteIcon from '../WebsiteIcon.vue'
import MediaModal from './MediaModal.vue'

export default {
  components: {
    EngagementRow,
    WebsiteIcon,
    MediaModal,
  },
  props: {
    result: Object,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      mediaModal: false,
    }
  },
}
</script>

<style scoped>
.media-card {
  border: var(--border-width-1) solid var(--color-tan-300);
  cursor: pointer;
}

.media-card:hover {
  border: var(--border-width-1) solid var(--color-primary-600);
  background: #dfc3ec33; /* var(--color-primary-100) with 20% opacity */
}

.thumbnail-container {
  height: 180px;
  overflow: hidden;
}

.thumbnail {
  height: 180px;
  background-size: cover;
  background-position: center;
}

.blur {
  filter: blur(25px);
}

.details {
  margin: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.data {
  display: flex;
  justify-content: space-between;
}

.card-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
<template>
  <GhostButton mode="dark" @click="modalOpen = true">
    Search Help
  </GhostButton>
  <BaseModal
    v-model="modalOpen"
    max-width="950px"
    width="950px"
    height="750px"
    title="Search help"
    no-route-dismiss
  >
    <div class="content-wrapper search-help">
      <q-card-section class="content">
      <Accordion
        style="margin-top: var(--spacing-xxl)"
        title="Options"
        :title-style="{
          fontSize: 'var(--fs-3)',
          lineHeight: 'var(--fs-6)',
          fontWeight: '700'
        }"
      >
        <div style="margin-top: var(--spacing-huge)">
          <div style="margin-top: var(--spacing-xl)" class="text-heading">
            Boolean
          </div>
          <div>
            This query option searches a boolean string only within the content field. The <span class="highlighted-area"><span class="highlighted-text">content</span></span> field is the text of a post or comment that was posted on the dataset/source. Here is a rundown on how to use boolean queries and associated boolean operators:
            <div>
            <div style="margin-top: var(--spacing-md)" class="highlighted-area">
              <div class="highlighted-text">""</div>
            </div>
            <span style="margin: 0 var(--spacing-md)">-</span><span>Put terms or keywords in double quotes for an exact match.</span>
          </div>
            <div>
            <div style="margin-top: var(--spacing-md)" class="highlighted-area">
              <div class="highlighted-text">AND</div>
            </div>
            <span style="margin: 0 var(--spacing-md)">-</span><span>All conditions must be met in order for a result to match.</span>
          </div>
            <div>
            <div style="margin-top: var(--spacing-md)" class="highlighted-area">
              <div class="highlighted-text">OR</div>
            </div>
            <span style="margin: 0 var(--spacing-md)">-</span><span>At least one condition must be met in order for a result to match.</span>
          </div>
            <div>
            <div style="margin-top: var(--spacing-md)" class="highlighted-area">
              <div class="highlighted-text">NOT</div>
            </div>
            <span style="margin: 0 var(--spacing-md)">-</span><span>Put in front of a term or keyword to exclude results.</span>
          </div>
          <div>
            <div style="margin-top: var(--spacing-md)" class="highlighted-area">
              <div class="highlighted-text">()</div>
            </div>
            <span style="margin: 0 var(--spacing-md)">-</span><span>Used to group operators together to create more complex queries.</span>
          </div>
          <div>
            <div style="margin-top: var(--spacing-md)" class="highlighted-area">
              <div class="highlighted-text">*</div>
            </div>
            <span style="margin: 0 var(--spacing-md)">-</span><span>wildcard search used to replace letter(s) and find variations of a word.</span>
          </div>
          </div>
          <div style="margin-top: var(--spacing-xl)" class="text-heading">
            Advanced
          </div>
          <div>
            This query option allows users to search within specific data fields and also allows for boolean operators. It is important to note that unless specified, the advanced option will query every data field for a match which could include an actor username, a post content or a video description. Users can specify data fields by leading with the data field with a colon followed by the search term(s). See
            <span class="highlighted-area"><span class="highlighted-text">Field Syntax</span></span>
            below for more information on the available fields that can be referenced.
          </div>
        </div>
      </Accordion>
      <Accordion
        style="margin-top: var(--spacing-huge)"
        title="Field Syntax"
        :default-open="true"
        :title-style="{
          fontSize: 'var(--fs-3)',
          lineHeight: 'var(--fs-6)',
          fontWeight: '700'
        }"
      >
        <div style="margin: var(--spacing-xxl) 0">
          <div>
            For a full list of available fields please see the <a href="https://docs.openmeasures.io/partners/tools/apis/pro-api/standard-schema" target="_blank" rel="noopener noreferrer">documentation</a>.
          </div>
          <div class="field-table-container">
            <table class="field-table">
              <thead>
                <tr>
                  <th>Object</th>
                  <th>Field Name</th>
                  <th>Field Type</th>
                  <th>Nullable</th>
                  <th>Description</th>
                  <th>Example Query</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tableData" :key="index">
                  <td>{{ row.object }}</td>
                  <td >{{ row.fieldName }}</td>
                  <td v-html="row.fieldType"></td>
                  <td>{{ row.nullable }}</td>
                  <td v-html="row.description"></td>
                  <td v-html="row.example || ''"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Accordion>
    </q-card-section>
    </div>
  </BaseModal>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import BaseModal from '../../BaseModal.vue'
import GhostButton from '../GhostButton.vue'

export default {
  components: {
    Accordion,
    BaseModal,
    GhostButton
  },
  data () {
    return {
      modalOpen: false,
      tableData: [
        {
          object: '',
          fieldName: 'url',
          fieldType: '<span class="highlighted-area"><span class="highlighted-text">AnyUrl</span></span>',
          nullable: 'TRUE',
          description: 'URL of the item',
          example: '<span class="highlighted-area"><span class="highlighted-text">url: truthsocial.com/geh123123</span></span>'
        },
        {
          object: '',
          fieldName: 'text',
          fieldType: '<span class="highlighted-area"><span class="highlighted-text">str</span></span>',
          nullable: 'TRUE',
          description: 'The text of the content',
          example: '<span class="highlighted-area"><span class="highlighted-text">text: "We are planning to go later this week. Will send a message when it\'s happening"</span></span>'
        },
        {
          object: 'openmeasures_media',
          fieldName: '_hash',
          fieldType: '<span class="highlighted-area"><span class="highlighted-text">str</span></span>',
          nullable: 'FALSE',
          description: 'Calculated hash of the media',
          example: '<span class="highlighted-area"><span class="highlighted-text">openmeasures_media._hash: 7d089683c7af1f2ea731a61f5b9092f967bec8b2</span></span>'
        },
        {
          object: 'openmeasures_media',
          fieldName: 'source_url',
          fieldType: '<span class="highlighted-area"><span class="highlighted-text">AnyUrl</span></span>',
          nullable: 'FALSE',
          description: 'URL of the media on the source',
          example: '<span class="highlighted-area"><span class="highlighted-text">openmeasures_media.source_url: "truthsocial.com/geh12312/video/w234.mp4"</span></span>'
        },
        {
          object: 'openmeasures_media',
          fieldName: 'thumbnail_hash',
          fieldType: '<span class="highlighted-area"><span class="highlighted-text">str</span></span>',
          nullable: 'TRUE',
          description: 'Calculated hash of the thumbnail',
          example: '<span class="highlighted-area"><span class="highlighted-text">openmeasures_media.thumbnail_hash: 34123ac423b2322f123f</span></span>'
        },
        {
          object: 'openmeasures_media',
          fieldName: 'thumbnail_url',
          fieldType: '<span class="highlighted-area"><span class="highlighted-text">AnyUrl</span></span>',
          nullable: 'TRUE',
          description: 'URL of the thumbnail on the source',
          example: '<span class="highlighted-area"><span class="highlighted-text">openmeasures_media.thumbnail_url: truthsocial.com/geh12312/video/w234.jpg</span></span>'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
  height: calc(750px - 100px); /* Subtract header height */
  overflow: hidden;
  margin-top: var(--spacing-md);
}

.content {
  margin: 0;
  font-size: var(--fs-2);
  font-weight: 400;
  line-height: var(--fs-5);
  color: var(--color-tan-950);
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: var(--spacing-sm); /* Add slight padding at top */

  /* Hide scrollbar but allow scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.text-heading {
  font-size: var(--fs-2);
  font-weight: 500;
  line-height: var(--fs-5);
  color: var(--color-tan-950);
  margin-bottom: var(--spacing-md);
}

.field-table-container {
  margin-top: var(--spacing-xl);
  overflow-x: visible; /* Changed from auto to prevent horizontal scrolling */
}


.field-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--spacing-lg);
  font-size: var(--fs-1);
  table-layout: fixed;
}

.field-table th {
  text-align: left;
  padding: var(--spacing-md) var(--spacing-md);
  border-bottom: 1px solid var(--color-tan-300);
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-4);
  color: var(--color-tan-600);
  text-transform: capitalize;
}

.field-table th:nth-child(1) {
  width: 10%;
}

.field-table th:nth-child(2) {
  width: 15%;
}

.field-table th:nth-child(3) {
  width: 20%;
}

.field-table th:nth-child(4) {
  width: 10%;
}

.field-table th:nth-child(5) {
  width: 25%;
}

.field-table th:nth-child(6) {
  width: 20%;
}

.field-table td {
  padding: var(--spacing-md) var(--spacing-md);
  border-bottom: 1px solid var(--color-tan-200);
  vertical-align: middle;
  color: var(--color-tan-950);
  font-weight: 300;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  min-width: 0;
}

.field-table td:nth-child(3) {
  word-break: break-all; /* Specifically for the Field Type column which has special characters */
}

.field-table td:nth-child(6) {
  word-break: break-word; /* For the Example column to ensure long content wraps */
  white-space: normal;
  overflow-wrap: break-word;
}

.field-table tbody tr:hover {
  background-color: var(--color-tan-50);
}
</style>

<style lang="scss">
/* Global styles for highlighted content in search-help */
.search-help .highlighted-area {
  background-color: var(--color-primary-100);
  border-radius: var(--spacing-xs);
  display: inline-flex;
  justify-content: center;
  align-self: center;
  max-width: 100%;
  flex-wrap: wrap;
}

.search-help .highlighted-text {
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-3);
  padding: var(--spacing-xs) var(--spacing-md);
  color: var(--color-primary-700);
}

/* Global styles for highlighted text in table cells */
.search-help .field-table td .highlighted-area + .highlighted-area {
  margin-left: var(--spacing-xs);
}

.search-help .field-table td .highlighted-text {
  font-weight: 300;
}
</style>
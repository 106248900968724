<template>
  <LoadingAnimation v-if="loading" class="loading" />
  <div v-else>
    <div class="media-grid">
      <MediaCard
        v-for="result in displayData"
        :key="result.link"
        :result="result"
        :blurThumbnails="blurThumbnails"
      />
      <div v-if="displayData.length === 0" class="no-media">
        No media available for this search. Try switching to the table view.
      </div>
    </div>
    <PaginationBar
      :totalResults="mediaData.length"
      :pageSize="itemsPerPage"
      v-model:currentPage="currentPage"
    />
  </div>
</template>

<script>
import LoadingAnimation from '../../animations/LoadingAnimation.vue'
import MediaCard from './MediaCard.vue'
import PaginationBar from '../PaginationBar.vue'

export default {
  components: {
    LoadingAnimation,
    MediaCard,
    PaginationBar,
  },
  props: {
    resultsData: Array,
    loading: Boolean,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      currentPage: 1,
      itemsPerPage: 20,
    }
  },
  computed: {
    mediaData () {
      return this.resultsData.filter(result => result.media.media_hash)
    },
    displayData () {
      return this.mediaData
        .slice(
          (this.currentPage - 1) * this.itemsPerPage,
          this.currentPage * this.itemsPerPage
        )
    },
  }
}
</script>

<style scoped>
.loading {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-grid {
  margin-top: var(--spacing-xxl);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 18px;
  padding-bottom: 4rem;
}

.no-media {
  grid-area: 1 / 1 / auto / last-column;
  justify-self: center;
}
</style>
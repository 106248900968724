<template>
  <DeleteCell v-if="col.name === 'delete'" :row="row"/>
  <ActiveCell v-else-if="col.name === 'media_active' && row.media_status !== null" :value="row.media_status" :row="row" active-type="media"/>
  <ActiveCell v-else-if="col.name === 'active'" :value="row.active ?? row.crawling_status" :row="row" active-type="crawl"/>
  <SourceCell
    v-else-if="row.source && col.name === 'source'"
    :value="row.source"
  />
  <template v-else-if="(row.last_fetched ?? row.updated_utc) && col.name === 'last_fetched'">
    {{ (row.last_fetched ?? row.updated_utc).split("T")[0] }}
  </template>
  <SeeResultsCell
    v-else-if="col.name === 'see_results'"
    :row="row"
  />
  <LongText
    v-else-if="row.id && col.name === 'id'"
    :value="row.id"
    :max-length="10"
  />
  <LongText
    v-else-if="row.source_url && col.name === 'source_url'"
    :value="row.source_url"
    :max-length="25"
  />
  <LongText
    v-else-if="row.keyword && col.name === 'keyword'"
    :value="row.keyword"
    :max-length="25"
  />
  <template v-else-if="col.name === 'channel'">
    {{ row.username }}
  </template>
  <template v-else-if="row[col.name]">
    {{ row[col.name] }}
  </template>
</template>

<script>
import DeleteCell from './DeleteCell.vue'
import ActiveCell from './ActiveCell.vue'
import SourceCell from '../SourceCell.vue'
import SeeResultsCell from './SeeResultsCell.vue'
import LongText from '../LongText.vue'

export default {
  components: {
    DeleteCell,
    ActiveCell,
    SourceCell,
    SeeResultsCell,
    LongText,
  },
  props: {
    col: Object,
    row: Object,
  },
}
</script>
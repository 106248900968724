<template>
  <div class="toggle-button">
    <div
      v-for="option in options"
      :key="option.value"
      @click="!option.disabled && $emit('click', option.value)"
      :class="['button', {
        'button-active': option.value === value,
        'button-disabled': option.disabled,
      }]"
    >
      <component
        :is="option.component"
        style="width: 16px; height: 16px"
        :class="['toggle-icon', { 'toggle-icon-active': option.value === value }]"
      />
      <BaseTooltip v-if="option.tooltip">{{ option.tooltip }}</BaseTooltip>
    </div>
  </div>
</template>

<script>
import BaseTooltip from '../BaseTooltip.vue'

export default {
  components: {
    BaseTooltip,
  },
  props: {
    value: String,
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.toggle-button {
  position: relative;
  cursor: pointer;
  background: transparent;
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  border: 1px solid var(--color-tan-950);
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;
  align-self: flex-start;
}

.button {
  padding: var(--spacing-xl);
}

.button:hover {
  background: var(--color-primary-100);
}

.button-active {
  background: var(--color-primary-600);
}

.button-active:hover {
  background: var(--color-primary-700);
}

.button-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.toggle-icon {
  color: var(--color-tan-950);
  width: 16px;
  height: 16px;
  overflow: visible;
}

.button:hover .toggle-icon:not(.toggle-icon-active) {
  color: var(--color-primary-700);
}


.toggle-icon-active {
  color: var(--color-white);
}

</style>
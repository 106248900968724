import { markRaw } from 'vue'
import LikeIcon from '../assets/svg/color/like.svg?component'
import ThumbDownIcon from '../assets/svg/color/thumbDown.svg?component'
import ReshareIcon from '../assets/svg/color/repost.svg?component'
import ReplyIcon from '../assets/svg/color/reply.svg?component'
import VisibilityIcon from '../assets/svg/color/visibility.svg?component'

const PostEngagement = [
  { value: 'likes', label: 'Likes', icon: markRaw(LikeIcon) },
  { value: 'dislikes', label: 'Dislikes', icon: markRaw(ThumbDownIcon) },
  { value: 'reshares', label: 'Reshares', icon: markRaw(ReshareIcon) },
  { value: 'replies', label: 'Replies', icon: markRaw(ReplyIcon) },
  { value: 'views', label: 'Views', icon: markRaw(VisibilityIcon) },
]

export default PostEngagement

export const ContextEngagement = [
  ...PostEngagement,
  { value: 'friends', label: 'Friends', icon: markRaw(LikeIcon) },
  { value: 'followers', label: 'Followers', icon: markRaw(LikeIcon) },
  { value: 'following', label: 'Following', icon: markRaw(LikeIcon) },
  { value: 'posts', label: 'Posts', icon: markRaw(LikeIcon) }
]
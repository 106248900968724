<template>
  <div
    :class="[`border-${mode}-mode`]"
    :style="{
      borderWidth: width
    }"
  />
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'light'
    },
    width: {
      type: String,
      default: 'var(--border-width-2)'
    },
  }
}
</script>

<style scoped>
.border-light-mode {
  border-top: solid var(--color-tan-300);
}

.border-dark-mode {
  border-top: solid var(--color-primary-700);
}
</style>
<template>
  <BaseCard>
    <template #top-right-overlay>
      <Button variant="secondary" @click="$refs.chart.downloadImage()">
        {{ $t("chart.downloadPNG") }}
      </Button>
    </template>
    <template #content>
      <ChartTimeline
        ref="chart"
        :resultsData="website"
        :searchSettings="searchSettings"
        :website="website.label"
        :timelineType="$t('chart.timelineOn')"
        :subTitle="$t('chart.postsPer')"
      />
    </template>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/BaseCard'
import ChartTimeline from '@/components/charts/ChartTimeline'
import Button from '@/components/input/Button'

export default {
  components: {
    BaseCard,
    ChartTimeline,
    Button,
  },
  props: {
    lastSearchSettings: Object,
    website: Object
  },
  computed: {
    searchSettings () {
      return this.lastSearchSettings
    },
  }
}
</script>

<style scoped lang="scss">
img {
  width: 25%;
}
</style>

<template>
  <div class="engagement-row">
    <div
      v-for="type in types"
      :key="type.value"
      class="flex-row-start"
      :class="{ dark }"
    >
      <div class="text-reaction">
        {{ engagement[type.value] ?? 0 }}
      </div>
      <component
        :is="type.icon"
        class="engagement-icon"
      />
    </div>
  </div>
</template>

<script>
import types from '../../constants/engagement'

export default {
  props: {
    engagement: Object,
    dark: Boolean,
  },
  data () {
    return {
      types,
    }
  }
}
</script>

<style scoped>
.engagement-row {
  display: flex;
  gap: var(--spacing-md);
}

.text-reaction {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-950);
}

.dark .text-reaction {
  color: var(--color-white);
}

.engagement-icon {
  color: var(--color-primary-600);
  margin-left: var(--spacing-xs);
}

.dark .engagement-icon {
  color: var(--color-primary-300);
}
</style>
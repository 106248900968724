import moment from 'moment'
import { PUBLIC_APP } from '@/constants/misc'
import { validateDateFormat } from '@/helpers/searchUtils'

export function validInput (settings) {
  const { searchTerm, startDate, endDate, websites, types } = settings

  return [startDate, endDate].every(isDateValidated) &&
    searchTerm?.trim() &&
    websites.filter((website) => website.active).length > 0 &&
    types.filter((type) => type.active).length > 0
}

export function dateIsOldEnough (date) {
  if (!PUBLIC_APP) return true

  // if we're on the public version then we only allow picking dates older than half a year
  const halfYearBack = moment().subtract(0.5, 'year').subtract(1, 'day')

  return moment(date).isBefore(halfYearBack)
}

function isDateValidated (date) {
  return validateDateFormat(date) && dateIsOldEnough(date)
}

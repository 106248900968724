<template>
  <div class="menu-container">
    <ThreeDotMenuIcon
      class="menu-icon"
      :style="{ color: isOpen ? 'var(--color-primary-600)' : 'var(--color-tan-800)' }"
      @click.stop="togglePopup"
    />
    <PopupWrapper
      v-model:isOpen="isOpen"
      :total-items="menuOptions.length"
      spacing="var(--spacing-sm)"
      class="menu-popup"
    >
      <DropDown class="menu-dropdown">
        <div
          v-for="(option, index) in menuOptions"
          :key="index"
          class="dropdown-item"
          @click.stop="handleOptionClick(option)"
        >
          {{ option.label }}
        </div>
      </DropDown>
    </PopupWrapper>
  </div>
</template>

<script>
import PopupWrapper from '../PopupWrapper.vue'
import DropDown from '../input/DropDown.vue'
import ThreeDotMenuIcon from '@/assets/svg/color/threeDotMenu.svg?component'

export default {
  components: {
    PopupWrapper,
    DropDown,
    ThreeDotMenuIcon
  },
  props: {
    menuOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    handleOptionClick (option) {
      this.$emit('option-click', option)
      this.isOpen = false
    },
    togglePopup () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style>
.q-focus-helper {
  display: none !important;
}
</style>

<style scoped>
.menu-container {
  position: relative;
  display: inline-block;
}

.menu-icon {
  position: relative;
  cursor: pointer;
  width: var(--fs-3);
  height: var(--fs-3);
}

.menu-popup {
  margin-left: -8.5rem;
  z-index: 1;
}

.menu-dropdown {
  width: 11.38rem;
}

.dropdown-item {
  padding: var(--spacing-md) var(--spacing-xxl);
  cursor: pointer;
  transition: background-color 0.2s;
  color: var(--color-tan-600);
  font-size: var(--fs-2);
  font-weight: 400;
}

.dropdown-item:hover {
  color: var(--color-primary-950);
  background-color: var(--color-primary-100);
}
</style>
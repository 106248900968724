<template>
  <InputDate
    :label="$t('setting.startDate')"
    :mode="mode"
    :value="internalValue"
    @input="updateValue"
  />
</template>

<script>
import InputDate from './InputDate'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    mode: String
  },
  components: {
    InputDate
  },
  computed: {
    internalValue: {
      get () {
        return this.modelValue // Get value from parent via v-model
      },
      set (value) {
        this.$emit('update:modelValue', value) // Emit changes to parent
      }
    }
  },
  methods: {
    updateValue (value) {
      this.internalValue = value // Update and emit changes
    }
  },
}
</script>

<style scoped lang="scss"></style>

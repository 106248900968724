<template>
  <div class="app">
    <TheTopBar
      ref="topBarRef"
      @toggleShowSettings="showSettingsBar = true"
      class="top-bar"
    />
    <div :style="{ marginTop: `${topBarHeight}px` }">
      <AnimatedAccordion v-if="onTool" :isExpanded="isPageUnscrolled">
        <SearchTermBar ref="searchTermBarRef"/>
      </AnimatedAccordion>
      <div
        class="page-content"
        :style="{ height: contentHeight }"
      >
        <div
          v-if="onTool && large && !isContextView"
          :class="['sidebar', { 'mini-state': sidebarMiniState }]"
          :style="{ height: contentHeight }"
        >
          <div class="sidebar-top-section">
            <template v-if="!sidebarMiniState">
              <div class="flex-row-start">
                <div class="selected-text">
                  {{ selectedSidebarFilterCategoryCount }} filter categories selected
                </div>
                <GhostButton
                  style="padding: var(--spacing-xl);"
                  size="sm"
                  @click="clearSidebarFilters"
                >
                  Clear all
                </GhostButton>
                <EnterArrowIcon
                  v-if="isSidebarFiltersModified"
                  class="enter-icon"
                  @click="clickSearch('search')"
                />
              </div>
            </template>
            <MiniButtonContainer class="flex-row-center"  @click="handleSidebarMiniState()">
              <MenuLeft class="menu-icon" v-if="!sidebarMiniState" />
              <MenuRight class="menu-icon" v-else />
            </MiniButtonContainer>
          </div>
          <div class="sidebar-bottom-border"/>
          <div
            v-if="!sidebarMiniState"
            ref="fadeEl"
            class="scrollable"
            :style="`margin-bottom: ${isPageUnscrolled ? searchTermBarHeight : 0}px; ${fadeStyle}`"
          >
            <router-view name="settings" />
          </div>
        </div>
        <ThePage
          style="overflow-y: auto"
          ref="pageRef"
          @scroll="handleScroll"
        />
        <ContextSearch
          v-if="onTool && large && isContextView"
          :style="{ height: contentHeight }"
        />
      </div>
    </div>
    <BaseModal v-if="onTool && !large" v-model="showSettingsBar">
      <TheSettingsBar />
    </BaseModal>
  </div>
</template>

<script>
import { createNamespacedHelpers, useStore } from 'vuex'
import { useTitle, useResizeObserver } from '@vueuse/core'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TheTopBar from '@/components/TheTopBar'
import TheSettingsBar from '@/components/TheSettingsBar'
import MiniButtonContainer from '@/components/input/MiniButtonContainer.vue'
import BaseModal from '@/components/BaseModal'
import ThePage from '@/components/ThePage'
import SearchTermBar from './components/SearchTermBar.vue'
import ContextSearch from './components/sidebar/ContextSearch.vue'
import AnimatedAccordion from '@/animations/AnimatedAccordion.vue'
import GhostButton from './components/input/GhostButton.vue'
import { isTool } from '@/helpers/searchQuery'
import EnterArrowIcon from './assets/svg/color/enterArrow.svg?component'
import MenuLeft from './assets/svg/color/menuLeft.svg?component'
import MenuRight from './assets/svg/color/menuRight.svg?component'
import { useFadeScroll } from './helpers/effects'

const { mapActions: mapSettingsActions, mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')
const { mapGetters: mapContextGetters } = createNamespacedHelpers('context')

export default {
  components: {
    TheTopBar,
    TheSettingsBar,
    MiniButtonContainer,
    ThePage,
    SearchTermBar,
    ContextSearch,
    BaseModal,
    AnimatedAccordion,
    EnterArrowIcon,
    MenuLeft,
    MenuRight,
    GhostButton
  },
  data () {
    return {
      isPageUnscrolled: true
    }
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()

    const showSettingsBar = ref(true)
    const large = ref(false)

    store.subscribeAction((action) => {
      if (action.type === 'searchSettings/clickSearch' && !large.value) {
        // TODO: removing this seems to have no effect, it still works?
        showSettingsBar.value = false
      }
    })

    const onTool = computed(() => isTool(route))

    const title = computed(() => {
      const name = t(`nav.${route.name}`)
      return `${name} | Open Measures`
    })
    useTitle(title)

    const useElementHeight = (elementRef) => {
      const height = ref(0)
      useResizeObserver(elementRef, (entries) => {
        const entry = entries[0]
        height.value = entry.borderBoxSize[0].blockSize
      })
      return height
    }

    const topBarRef = ref(null)
    const searchTermBarRef = ref(null)

    const topBarHeight = useElementHeight(topBarRef)
    const searchTermBarHeight = useElementHeight(searchTermBarRef)

    const { fadeEl, fadeStyle } = useFadeScroll()


    return { showSettingsBar, large, onTool, topBarRef, topBarHeight, searchTermBarRef, searchTermBarHeight, fadeEl, fadeStyle }
  },
  methods: {
    ...mapSettingsActions(['onCreated', 'clickSearch']),
    ...mapMutations(['clearSidebarFilters', 'setSidebarMiniState']),
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    },
    handleScroll () {
      // Use requestAnimationFrame for smoother transitions
      requestAnimationFrame(() => {
        const scrollTop = this.$refs.pageRef.$el.scrollTop
        // Only change state when crossing the threshold to avoid unnecessary re-renders
        if (scrollTop > 10 && this.isPageUnscrolled) {
          this.isPageUnscrolled = false
        } else if (scrollTop <= 10 && !this.isPageUnscrolled) {
          this.isPageUnscrolled = true
        }
      })
    },
    handleSidebarMiniState () {
      this.setSidebarMiniState(!this.sidebarMiniState)
    }
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.updateLarge)
    this.onCreated(this.$store)
  },
  computed: {
    ...mapContextGetters(['isContextView']),
    ...mapGetters([
      'sidebarMiniState',
      'isSidebarFiltersModified',
      'selectedSidebarFilterCategoryCount'
    ]),
    contentHeight () {
      if (!this.onTool) {
        return `calc(100vh - ${this.topBarHeight}px)`
      } else {
        // Always use the same calculation, let the AnimatedAccordion handle the transition
        return `calc(100vh - ${this.topBarHeight}px)`
      }
    },
  },
}
</script>

<style lang="scss">
@import "global";
</style>

<style scoped lang="scss">
a {
  text-decoration: none !important;
}

.app {
  height: 100vh !important;
  overflow: hidden
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.page-content {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  background: var(--color-tan-100);
  padding: var(--spacing-xxl) var(--spacing-xxl);
  border-right: var(--border-width-1) solid var(--color-light-border);
  text-align: left;
  color: var(--color-black);
  width: 366px;
  overflow-y: auto;
}

.scrollable {
  flex: 1;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0 calc(-1 * var(--spacing-xxl));
  padding: 0 var(--spacing-xxl);
  scrollbar-width: none; /* Firefox */
}

.scrollable::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.enter-icon {
  cursor: pointer;
  width: var(--fs-3);
  height: var(--fs-3);
  margin-left: var(--spacing-xl);
  color: var(--color-tan-950);
}

.menu-icon {
  width: var(--fs-7);
  height: var(--fs-7);
}

.sidebar-bottom-border {
  margin-top: var(--spacing-xxl);
  margin-left: calc(-1 * var(--spacing-4));
  margin-right: calc(-1 * var(--spacing-4));
  border-top: var(--border-width-2) solid var(--color-tan-300);
}

.sidebar.mini-state {
  width: 4.714rem;
  padding: var(--spacing-xxl)  var(--spacing-xxl) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.sidebar-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-text {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-600);
}

.sidebar-title {
  background: var(--color-off-white);
  color: var(--color-black);
  font-size: 20px;
  font-weight: bold;
}

.mini-slot {
  background: var(--color-off-white);
}

.close-sidebar {
  top: 10px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
}
</style>

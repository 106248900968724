<template>
  <div class="label-toggle">
    <BaseToggle
      :model-value="modelValue"
      :disabled="disabled"
      @update:model-value="$emit('update:model-value', $event)"
    />
    <div
      class="label"
      :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
      @click="!disabled && $emit('update:model-value', !modelValue)"
    >
      {{ label }}
    </div>
    <BaseTooltip v-if="tooltip">{{ tooltip }}</BaseTooltip>
  </div>
</template>

<script>
import BaseTooltip from '../BaseTooltip.vue'
import BaseToggle from './BaseToggle.vue'

export default {
  components: {
    BaseTooltip,
    BaseToggle,
  },
  props: {
    modelValue: Boolean,
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: String,
  }
}
</script>

<style scoped>
.label-toggle {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

.label {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-950);
}
</style>
<template>
  <div class="image-viewer">
    <div class="top-bar">
      <LongText class="title" :value="row.text" max-length="30" />
      <div class="right">
        <EngagementRow :engagement="row.engagement" dark/>
        <WebsiteIcon :website="row.source.name"/>
        <GhostButton @click="download" variant="secondary" mode="dark" no-padding>
          <DownloadIcon/>
        </GhostButton>
      </div>
    </div>
    <div class="image-bg">
      <BodyLoading
        v-if="!mediaUrl ||
          (mimetype === 'application/pdf' && !pdfData)"
      />
      <div
        v-else-if="mimetype?.startsWith('image/')"
        class="media"
        :style="{
          'background-image': `url(${mediaUrl}`
        }"
      />
      <iframe
        v-else-if="mimetype === 'application/pdf'"
        class="media"
        :src="`${pdfData}#navpanes=0&view=Fit`"
        frameBorder="0"
        scrolling="auto"
        height="100%"
        width="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import EngagementRow from './EngagementRow.vue'
import WebsiteIcon from '../WebsiteIcon.vue'
import GhostButton from '../input/GhostButton.vue'
import DownloadIcon from '../../assets/svg/color/download.svg?component'
import BodyLoading from '../bodies/BodyLoading.vue'
import LongText from '../LongText.vue'

export default {
  components: {
    BodyLoading,
    EngagementRow,
    WebsiteIcon,
    GhostButton,
    DownloadIcon,
    LongText,
  },
  props: {
    mediaUrl: String,
    mimetype: String,
    row: Object,
  },
  data () {
    return {
      pdfData: null,
    }
  },
  methods: {
    download () {
      const a = document.createElement('a')
      a.href = this.mediaUrl
      a.click()
    }
  },
  watch: {
    mediaUrl: {
      handler () {
        // mediaUrl often starts out as null so we wait a bit
        if (
          this.mediaUrl &&
          !this.pdfData &&
          this.mimetype === 'application/pdf'
        ) {
          fetch(this.mediaUrl)
            .then(res => res.blob())
            .then(blob => {
              blob = blob.slice(0, blob.size, 'application/pdf')
              this.pdfData = URL.createObjectURL(blob)
            })
        }
      },
      immediate: true,
    },
  }
}
</script>

<style scoped>
.image-viewer {
  width: 65vw;
  height: 65vh;
}

.top-bar {
  background-color: var(--color-primary-950);
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-xxxl) var(--spacing-huge);
}

.title {
  font-size: var(--fs-3);
  font-weight: 700;
}

.right {
  display: flex;
  gap: var(--spacing-huge);
}

.loading {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-bg {
  width: 100%;
  height: 100%;
  background: url(~@/assets/pixely-bg.png) var(--color-tan-100);
}

.media {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<template>
  <div>
    <div class="banner">
      {{ $t("home.banner") }}
    </div>
    <div class="container">
      <div class="text-title-3 spacing">
        {{ $t(`nav.${CRAWLREQUESTS}`) }}
      </div>
      <TypeTabs class="spacing" @newTab="newTab"/>
      <div class="above-table">
        <TableTopLeft
          @adding="setLoading(true)"
          @added="newQuery()"
        />
        <q-input
          borderless
          dense
          debounce="1000"
          :model-value="searchTerm"
          @update:model-value="newSearchTerm"
          placeholder="Search"
        >
          <template v-slot:append>
            <BaseIcon name="search"/>
          </template>
          <BaseTooltip>
            Search for requested keywords
          </BaseTooltip>
        </q-input>
      </div>
      <CustomTable
        :loading="loading"
        :resultsData="rowData"
        :columnConfig="columnConfig"
        :header-style="{
          position: 'sticky',
          top: '0',
          zIndex: '2',
          paddingTop: 'var(--spacing-xl)',
          marginBottom: 'var(--spacing-xl)',
          backgroundColor: 'var(--color-white)',
        }"
        content-style="padding-bottom: 4rem"
      >
        <template #header="{ col }">
          <TableHeader :col="col"/>
        </template>
        <template #cell="{ row, col }">
          <TableCell
            :row="row"
            :col="col"
          />
        </template>
        <template #pagination>
          <PaginationBar
            :currentPage="currentPage"
            @update:currentPage="newPage"
            :totalResults="totalRowCount"
            :pageSize="15"
            only-prev-next
          />
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import router from '../router'
import { CRAWLREQUESTS } from '../constants/tools'
import { KEYWORDS, CHANNELS, PROFILES } from '../constants/crawlRequests'
import TableTopLeft from '../components/crawlRequests/TableTopLeft.vue'
import TypeTabs from '../components/crawlRequests/TypeTabs.vue'
import TableHeader from '../components/crawlRequests/TableHeader.vue'
import CustomTable from '../components/customTable/index.vue'
import TableCell from '../components/crawlRequests/TableCell.vue'
import PaginationBar from '../components/PaginationBar.vue'
import BaseIcon from '../components/BaseIcon.vue'
import BaseTooltip from '../components/BaseTooltip.vue'

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers('crawlRequests')

export default {
  components: {
    TableTopLeft,
    TypeTabs,
    TableHeader,
    CustomTable,
    TableCell,
    PaginationBar,
    BaseIcon,
    BaseTooltip,
  },
  data () {
    return {
      CRAWLREQUESTS,
      currentPage: 1,
    }
  },
  computed: {
    ...mapState(['loading', 'searchTerm', 'sites']),
    ...mapGetters(['type', 'isAdmin', 'rowData', 'totalRowCount']),
    visibleColumns () {
      if (this.isAdmin) {
        if (this.type === KEYWORDS) {
          return ['active', 'source', 'last_fetched', 'id', 'keyword', 'see_results']
        } else if (this.type === PROFILES) {
          return ['active', 'source', 'last_fetched', 'id', 'profile', 'source_id', 'source_url', 'datatype']
        } else if (this.type === CHANNELS) {
          return ['media_active', 'active', 'last_fetched', 'channel', 'id', 'see_results']
        }
      } else {
        if (this.type === KEYWORDS) {
          return ['delete', 'source', 'last_fetched', 'id', 'keyword', 'see_results']
        } else if (this.type === PROFILES) {
          return ['delete', 'active', 'source', 'last_fetched', 'id', 'profile', 'source_id', 'source_url', 'datatype']
        } else if (this.type === CHANNELS) {
          return ['delete', 'active', 'last_fetched', 'channel', 'id', 'see_results']
        }
      }

      return []
    },
    columnConfig () {
      const smallCol = 2
      const mediumCol = 5

      return [
        {
          flex: smallCol,
          name: 'delete',
          label: 'Delete',
        },
        {
          flex: smallCol,
          name: 'media_active',
          label: 'Media Active',
        },
        {
          flex: smallCol,
          name: 'active',
          label: 'Crawl Active',
        },
        {
          flex: mediumCol,
          name: 'source',
          label: 'Source',
        },
        {
          flex: mediumCol,
          name: 'last_fetched',
          label: 'Last Fetched',
        },
        {
          flex: mediumCol,
          name: 'channel',
          label: 'Channel',
        },
        {
          flex: mediumCol,
          name: 'id',
          label: 'ID',
        },
        {
          flex: mediumCol,
          name: 'profile',
          label: 'Username',
        },
        {
          flex: mediumCol,
          name: 'source_id',
          label: 'Source ID',
        },
        {
          flex: mediumCol,
          name: 'source_url',
          label: 'Source URL',
        },
        {
          flex: mediumCol,
          name: 'datatype',
          label: 'Datatype',
        },
        {
          flex: mediumCol,
          name: 'keyword',
          label: 'Keyword',
        },
        {
          flex: mediumCol,
          name: 'see_results',
          label: 'See Results',
        }
      ].filter(col => this.visibleColumns.includes(col.name))
    },
  },
  methods: {
    ...mapMutations(['setLoading', 'setSearchTerm', 'clearResults']),
    ...mapActions(['query', 'queryTimeseries']),
    async newQuery (args) {
      await this.query(args)
      this.resetPagination()
    },
    newTab (newType) {
      this.clearResults()
      this.setSearchTerm('')

      this.newQuery({ type: newType })
    },
    newSearchTerm (newSearchTerm) {
      this.setSearchTerm(newSearchTerm)
      this.$router.replace({
        name: CRAWLREQUESTS,
        params: { type: this.type },
        query: { searchTerm: newSearchTerm },
      })
      this.newQuery()
    },
    newPage (newPage) {
      const oldPage = this.currentPage
      this.currentPage = newPage
      this.query({
        prevPage: oldPage > newPage,
        nextPage: oldPage < newPage,
        page: newPage,
      })
    },
    resetPagination () {
      this.currentPage = 1
    }
  },
  watch: {
    sites () {
      this.newQuery()
    },
  },
  async created () {
    await router.isReady()
    const searchTerm = this.$route.query.searchTerm
    this.setSearchTerm(searchTerm)
    this.newQuery()
  }
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  background-color: var(--color-blue);
  color: #212529;
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.spacing {
  margin: var(--spacing-3) 0;
}

.above-table {
  display: flex;
  justify-content: space-between;
}

.table-bottom {
  margin-left: auto;
}
</style>